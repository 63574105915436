const CricketFightModule = () => import(/* webpackChunkName: "my-market-module" */ './views/Module.vue');
const CricketFight = () => import(/* webpackChunkName: "my-market" */ './views/CricketFight.vue');
const MatchDetails = () => import(/* webpackChunkName: "my-market" */ './views/MatchDetails.vue');
const PlaceBet = () => import(/* webpackChunkName: "my-market" */ './views/PlaceBet.vue');
const MyContests = () => import(/* webpackChunkName: "my-market" */ './views/MyContests.vue');
 const MyContestBetDetails = () => import(/* webpackChunkName: "my-market" */ './views/MyContestBetDetails.vue');
// import './styles/cricket-fight.css';
// import './styles/cricket-fight-responsive.css';
const CricketFightRoutes = {
    path: '/',
    component: CricketFightModule,
    children: [
        {
            path: 'cricket-fight',
            name: 'CricketFight',
            component: CricketFight
        },
        {
            path: 'cricket-fight/:id',
            name: 'MatchDetails',
            component: MatchDetails
        },
        {
            path: 'cricket-fight/:id/place-bet',
            name: 'PlaceBet',
            component: PlaceBet
        },
        {
            path: 'cricket-fight/my-contests',
            name: 'MyContests',
            component: MyContests
        },
        {
            path: 'cricket-fight/my-contests/:id',
            name: 'MyContestBetDetails',
            component: MyContestBetDetails
        },

        
    ],
}

export default CricketFightRoutes;