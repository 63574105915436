const state = {
    siteSettings: null,
    gamesData: null,
    // depositAmount: 0,
    globalLoader:false,
    searchText:null,
    captchaData: null,

};
const getters = {
    siteSettings: state => state?.siteSettings,
    gamesData: state => state?.gamesData,
    // depositAmount:state => state?.depositAmount,
    globalLoader:state => state?.globalLoader,
    searchText:state => state?.searchText,
    captchaData: state => state?.captchaData,
};
const actions = {
    setSettings({ commit }, siteSettings) {
        commit('setSettings', siteSettings);
    },
    setGamesData({ commit }, gamesData) {
        commit('setGamesData', gamesData);
    },
    setDepositAmount({ commit }, depositAmount) {
        commit('setDepositAmount', depositAmount);
    },
    setGlobalLoader({ commit }, globalLoader) {
        commit('setGlobalLoader', globalLoader);
    },
    setSearchText({commit},searchText){
        commit('setSearchText', searchText);
    },
    setCaptchaData({ commit }, captchaData) {
        commit('setCaptchaData', captchaData);
    },
};
const mutations = {
    setSettings(state, siteSettings) {
        state.siteSettings = siteSettings
    },
    setGamesData(state, gamesData) {
        state.gamesData = gamesData
    },
    setDepositAmount(state, depositAmount) {
        state.depositAmount = depositAmount
    },
    setGlobalLoader(state, globalLoader) {
        state.globalLoader = globalLoader;
    },
    setSearchText(state, searchText) {
        state.searchText = searchText;;
    },
    
    setCaptchaData(state, captchaData) {
        state.captchaData = captchaData;
    },
};
export default {
    state,
    getters,
    actions,
    mutations
};