const BetsModule = () => import(/* webpackChunkName: "bets-module" */ './views/Module.vue');
const Bets = () => import(/* webpackChunkName: "bets" */ './views/Bets.vue');
const WinLoss = () => import(/* webpackChunkName: "win-loss" */ './views/WinLoss.vue');
const OpenBets = () => import(/* webpackChunkName: "open-bets" */ './views/OpenBets.vue');


const BetsRoutes = {
    path: '/',
    component: BetsModule,
    children: [
        {
            path: 'bets',
            name: 'bets',
            component: Bets,
        },
        // {
        //     path: 'match-market',
        //     name: 'match-market',
        //     component: MatchMarket,
        // },
        {
            path: 'win-loss',
            name: 'win-loss',
            component: WinLoss,
        },
        {
            path: 'open-bets',
            name: 'open-bets',
            component: OpenBets,
        }
    ],
}

export default BetsRoutes;