<template>
    <div v-if="siteSettings">
        <div class="container-fluid">
            <div class="last-end-section">
                <div class="betfair">
                    <ul class="cmn-ul-list">
                        <li>
                            <a href="#install-apk-model" data-bs-toggle="modal">
                                <img class="gc-logo" src="@/assets/images/download-android-app-icon.svg" alt=""></a>
                        </li>
                    </ul>
                </div>
            </div>
            <div v-if="siteSettings.user_journey" class="tutorials-btns">
                <ul class="cmn-ul-list">
                    <li>
                        <a
                            @click="chooseUserJourney(siteSettings.user_journey.introduction_video, 'Introduction')">Introduction</a>
                    </li>
                    <li>
                        <a @click="chooseUserJourney(siteSettings.user_journey.register_video, 'How to Register')">How to
                            register</a>
                    </li>
                    <li>
                        <a @click="chooseUserJourney(siteSettings.user_journey.deposit_video_e, 'How to Deposit')">How to
                            deposit</a>
                    </li>
                    <li>
                        <a @click="chooseUserJourney(siteSettings.user_journey.use_bonus_video, 'How to use bonus')">How to
                            use bonus</a>
                    </li>
                </ul>
            </div>
            <div v-if="siteSettings && siteSettings?.business_type == 2" class="whatsapp-ftr-btn">
                <a :href="'//wa.me/' + siteSettings?.social_link?.whatsappno1" target="_blank"
                    v-if="siteSettings.social_link && siteSettings?.social_link?.whatsappno1">
                    <div class="whatsapp-number">
                        <div class="landing-footer-social-button whatsapp"></div>
                        <div class="whatapp-name">WHATSAPP NOW!</div>
                    </div>
                </a>
            </div>
            <div v-if="siteSettings.social_link" class="mobile-social-icon">
                <div class="footer-social-bx">
                    <div v-if="siteSettings.social_link.whatsappno1" class="f-social-icon">
                        <a :href="'//wa.me/' + siteSettings.social_link.whatsappno1" target="_blank"><img
                                src="@/assets/images/WhatsApp.webp" alt="" /></a>
                    </div>

                    <div v-if="siteSettings.social_link.youtube_link" class="f-social-icon">
                        <a :href="siteSettings.social_link.youtube_link" target="_blank">
                            <img src="@/assets/images/youtub-icon.svg" alt="youtub" />
                        </a>
                    </div>

                    <div v-if="siteSettings.social_link.telegram_link" class="f-social-icon">
                        <a :href="siteSettings.social_link.telegram_link" target="_blank"><img
                                src="@/assets/images/telegram.svg" alt="" /></a>
                    </div>
                    <div v-if="siteSettings.social_link.instagram_link" class="f-social-icon">
                        <a :href="siteSettings.social_link.instagram_link" target="_blank"><img
                                src="@/assets/images/instagram.svg" alt="" /></a>
                    </div>

                    <div v-if="siteSettings.social_link.facebook_link"  class="f-social-icon">
                        <a :href="siteSettings.social_link.facebook_link" target="_blank">
                            <img src="@/assets/images/facebook-icon.svg" alt="facebook" />
                        </a>
                    </div>

                </div>
            </div>
            <div class="gc-companies-sec">
                <div class="gc-companies-img"><a><img src="@/assets/images/gc-logo.png" alt="gaming"></a></div>
                <p class="gc-companies-info">LOTUS 399 is a limited liability company incorporated under the laws of Curacao
                    Players are requested not to contact any untrusted sources for LOTUS 399 accounts as this is an online
                    site
                    and they can only register independently without any agents. Only deposit through the account details
                    generated by the system or provided by our official support team.</p>
            </div>
            <div v-if="siteSettings?.business_type == 2" class="payment-methode-sec">
                <ul class="cmn-ul-list">
                    <li><a ><img src="@/assets/images/payment-1.png" alt=""></a></li>
                    <li><a ><img src="@/assets/images/payment-2.png" alt=""></a></li>
                    <li><a ><img src="@/assets/images/payment-3.png" alt=""></a></li>
                    <li><a ><img src="@/assets/images/payment-4.png" alt=""></a></li>
                    <li><a ><img src="@/assets/images/payment-5.png" alt=""></a></li>
                    <li><a ><img src="@/assets/images/payment-6.png" alt=""></a></li>
                    <li><a ><img src="@/assets/images/payment-7.png" alt=""></a></li>
                    <li><a ><img src="@/assets/images/payment-8.png" alt=""></a></li>
                    <li><a ><img src="@/assets/images/payment-9.png" alt=""></a></li>
                    <li><a ><img src="@/assets/images/payment-11.png" alt=""></a></li>
                </ul>
            </div>
        </div>
        <div class="sub-footer">
            <div class="container-fluid">
                <div v-if="siteSettings.social_link"  class="footer-social-bx">
                    <div v-if="siteSettings.social_link.whatsappno1" class="f-social-icon">
                        <a :href="'//wa.me/' + siteSettings.social_link.whatsappno1" target="_blank"><img
                                src="@/assets/images/WhatsApp.webp" alt="" /></a>
                    </div>

                    <div v-if="siteSettings.social_link.youtube_link" class="f-social-icon">
                        <a :href="siteSettings.social_link.youtube_link" target="_blank">
                            <img src="@/assets/images/youtub-icon.svg" alt="youtub" />
                        </a>
                    </div>

                    <div v-if="siteSettings.social_link.telegram_link" class="f-social-icon">
                        <a :href="siteSettings.social_link.telegram_link" target="_blank"><img
                                src="@/assets/images/telegram.svg" alt="" /></a>
                    </div>
                    <div v-if="siteSettings.social_link.instagram_link" class="f-social-icon">
                        <a :href="siteSettings.social_link.instagram_link" target="_blank"><img
                                src="@/assets/images/instagram.svg" alt="" /></a>
                    </div>

                    <div v-if="siteSettings.social_link.facebook_link" class="f-social-icon">
                        <a :href="siteSettings.social_link.facebook_link" target="_blank">
                            <img src="@/assets/images/facebook-icon.svg" alt="facebook" />
                        </a>
                    </div>
                </div>
                <div class="copyrights-bx">
                    <p><router-link :to="{ name: 'policies', params: { type: 'RulesRegulations' } }" >Rules Regulations</router-link> 2016-2022 v. 9.66</p>
                </div>
            </div>
        </div>

        <div class="whatapp-chat-icons" v-if="siteSettings && siteSettings?.business_type == 2">
            <div class="whatapp-btn-fixed whatsApp">
                <a :href="'//wa.me/' + siteSettings?.social_link?.whatsappno1" target="_blank"
                    v-if="siteSettings?.social_link && siteSettings?.social_link?.whatsappno1">
                    <img src="@/assets/images/whats-app.svg" alt="">
                    <div :class="currentClass" class="circleIconPanel"></div>
                </a>
            </div>
            <div class="chat-btn-fixed">
                <a href="#chatboat-modal" data-bs-toggle="modal"><img src="@/assets/images/chat-icon.svg" alt=""></a>
            </div>
        </div>

    </div>

    <div class="modal fade game-play-modal" @close="showUserJourneyVideo = false" @click="showUserJourneyVideo = false"
        id="show_user_journey_video" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog ">
            <VideoModal v-if="showUserJourneyVideo" :title="choosedUserJourney ? choosedUserJourney.title : null"
                :link="choosedUserJourney ? choosedUserJourney.link : null" />
        </div>
    </div>

    <div ref="show_user_journey_video" style="display: none;" data-bs-toggle="modal"
        data-bs-target="#show_user_journey_video">
    </div>

    <div class="top-scroll">
        <div class="container">
            <button v-if="showButton" @click="scrollToTop"><i class="fa-solid fa-angle-up"></i></button>
        </div>
    </div>

<DownloadApkModal></DownloadApkModal>
</template>
<script>

import VideoModal from '@/shared/components/modal/VideoModal.vue';
import ChatModal from './ChatModal.vue';
import DownloadApkModal from '@/shared/components/modal/DownloadApkModal.vue';

export default {
    name: 'Footer',
    data() {
        return {
            classes: ['hideMessage', 'showMessage'],
            currentClassIndex: 0,
            hide: false,
            choosedUserJourney: { link: null, title: null },
            showButton: false,
            showUserJourneyVideo: false
        }
    },
    computed: {
        siteSettings() {
            return this.$store.getters.siteSettings;
        },
        domainName() {
            return this.$store.getters.domainName;
        },
        currentClass() {
            return this.classes[this.currentClassIndex];
        },

    },
    components: {
    VideoModal,
    ChatModal,
    DownloadApkModal
},

mounted() {
    // Add a scroll event listener
    window.addEventListener("scroll", this.handleScroll);
    setInterval(() => {
      this.currentClassIndex = (this.currentClassIndex + 1) % this.classes.length;
    }, 3000);
  },
  beforeDestroy() {
    // Remove the scroll event listener to prevent memory leaks
    window.removeEventListener("scroll", this.handleScroll);
  },
    methods: {
        handleScroll() {
            // Check the scroll position
            if (window.scrollY >= 50) {
                this.showButton = true;
            } else {
                this.showButton = false;
            }
            },
            scrollToTop() {
            // Scroll to the top of the page
            window.scrollTo({
                top: 0,
                behavior: "smooth"
            });
            },
        chooseUserJourney(userJourneLink, title) {
            if (userJourneLink) {
                userJourneLink = this.siteSettings.aws_url + this.siteSettings.storage_path.user_journey_path + userJourneLink;
            }
            this.choosedUserJourney = { link: userJourneLink, title: title };
            //this.$bvModal.show('user_journey');
            this.showUserJourneyVideo = true;
            this.$refs.show_user_journey_video.click();
            
        },
    }
}

</script>