
export const SITE_SETTINGS='api/site-settings'
export const GET_SPORTS= 'v1/sports/management/getSport';
export const LOGOUT_API='api/logout';
export const AUTH_STATUS='api/auth-status';
export const CHANGE_STAKE_VALUES='api/change-stake-values';
export const GET_FAVOURITE_LIST='api/favorite-list';
export const AUTH_REFRESH='api/auth-refresh';
export const WALLET_BALANCE='wallet';
export const SEARCH='api/search';
export const GET_WALLET_GETWAY='api/get-payment-getway-iframe-url';

