<template>
    <div v-if="searchResult!=null && (searchResult.matches?.length!=0 || searchResult.casino?.length!=0) " class="auto-search-bx">
        <div class="poligonSearch"><img src="assets/images/poligonIcon.svg" alt=""></div>
        <div v-if="this.searchResult.matches" class="searchList">
            <div v-for="(sport, s_index) in this.searchResult.matches" class="searchList-text">
                <a @click="openSportEventDetailPage(sport)">
                    <i class="sport-icn-img">
                        <img v-if="sport.sport_id == 4" src="@/assets/images/cricket-icon.svg" alt="Banner-icon">
                        <img v-if="sport.sport_id == 1" src="@/assets/images/football-icon.svg" alt="Banner-icon">
                        <img v-if="sport.sport_id == 2" src="@/assets/images/tennis-icon.svg" alt="Banner-icon">
                    </i>
                    <div class="ip-lft-text">
                        <span>{{ sport.event_name }}</span>
                        <p>{{ sport.league_name }}</p>
                    </div>
                    <div v-if="sport.inplay" class="ip-rit-bx">
                        <ul class="cmn-ul-list">
                            <li>
                                <div class="play-btn"><i class="inplay-broadcast-icon"><img
                                            src="@/assets/images/inplay-broadcast-icon.svg" alt=""></i></div>
                            </li>
                        </ul>
                    </div>
                </a>
            </div>

        </div>
        <div v-if="searchResult.casino" class="searchList">
            <div v-for="(game, g_index) in this.searchResult.casino" class="searchList-text">
                <a @click="clickOnGamestButton(game)">
                    <i class="sport-icn-img"> <img class="game_item_img" :src="game.image" alt="Player-icon"></i>
                    <div class="ip-lft-text" style="margin-left: 10px;">
                        <span>{{ game.game_name }}</span>
                        <p>{{ game.provider }}</p>
                    </div>
                </a>
            </div>
        </div>
    </div>


    <div ref="open_login_modal" style="display: none;" data-bs-toggle="modal" data-bs-target="#login-casino-btn">
    </div>

    <div ref="games_pop_1_modal" style="display: none;" data-bs-toggle="modal" data-bs-target="#static_games_pop_1">
    </div>

    <div ref="games_pop_2_modal" style="display: none;" data-bs-toggle="modal" data-bs-target="#static_games_pop_2">
    </div>

    <div ref="iframe_pop_modal_search" style="display: none;" data-bs-toggle="modal" data-bs-target="#iframe_pop_modal_search">
    </div>


    <div class="modal fade game-play-modal" id="iframe_pop_modal_search" tabindex="-1" aria-labelledby="exampleModalLabel"
        aria-hidden="true" @click="closeModalIframe()">
        <div  class="modal-dialog modal-fullscreen" style="max-width: 100%;">
             v-if="openIframeModal" />
        </div>

    </div>

</template>

<script>
import { AUTH_TOKEN } from '@/shared/constants/cookies-const';
import { setGameItem } from '@/shared/constants/shared-data';
import api from '@/shared/services/api';
import * as apiName from '@/shared/services/urls';
import IFrameModal from '@/modules/games/components/IFrameModal.vue';

export default {
    name: 'SearchList',
    data() {
        return {
            searchLoading: false,
            searchResult: false,
            openIframeModal:false
        }
    },
    methods: {
        searchCasinoMatches() {

            if (!this.searchLoading) {
                if (this.getSearchString() != '' && this.getSearchString().length > 3) {
                    try {
                        this.searchResult = null;
                        this.searchLoading = true;
                        let headers = {
                            'Authorization': `Bearer ${this.$cookies.get(AUTH_TOKEN)}`
                        }
                        let data = {
                            "search": this.getSearchString()
                        };
                        api.post(apiName.SEARCH, data, { headers }).then(response => {
                            this.searchLoading = false;
                            if (response && response.status && response.status == 200) {
                                let resData = response.data.data ?? null;
                                // if (resData) {
                                //     resData.matches.forEach(item => {
                                //         if (this.favourite_events.length && this.favourite_events.includes(item.event_id)) {
                                //             item.favourite = true;
                                //         } else {
                                //             item.favourite = false;
                                //         }
                                //     });
                                // }
                                console.log("Search result : ", resData);
                                this.searchResult = resData;


                            } else {
                                this.searchLoading = false;
                                this.showErrorModalFunc(response.data.debug[0]);
                            }
                        }).catch(error => {
                            this.searchLoading = false;
                            if (error) {
                                this.showErrorModalFunc(error[0]);
                            }
                        });
                    } catch (error) {
                        console.log("Search Error : ", error);
                    }
                }
                else {
                    this.searchResult = null
                }
            } else {
                this.searchResult = null
            }
        },

        openSportEventDetailPage(match){
            this.searchResult =null
            this.$router.push('/sports-event-detail/' + match.event_id )
        },

        clickOnGamestButton(item) {
            if (this.checkIsLogin()) {
                this.openGamesPopup(item);
            }
            else {
                this.$refs.open_login_modal.click();
            }
        },
        checkIsLogin() {
            return this.$store.getters.isAuthenticated;
        },
        userData() {
            return this.$store.getters.stateUser;
        },
        openGamesPopup(gameItem) {

            console.log("this.userData() : ", this.userData());
            setGameItem(gameItem);
            switch (this.userData().currency) {
                case 1:
                    if (gameItem.only_hkd == 1) {
                        this.$refs.games_pop_1_modal.click();
                    }
                    else if (gameItem.only_hkd==null || gameItem.only_hkd == 0) {
                        this.openIframeModal = true;
                        this.$refs.iframe_pop_modal_search.click();
                    }
                    break;
                case 2:
                    if (gameItem.only_hkd == 1) {
                        this.$refs.games_pop_1_modal.click();
                    }
                    else if (gameItem.only_hkd==null || gameItem.only_hkd == 0) {
                        this.$refs.games_pop_2_modal.click();
                    }
                    break;
                case 3:
                case 4:
                    this.openIframeModal = true;
                    this.$refs.iframe_pop_modal_search.click();
                    break;
            }
        },

        closeModalIframe(){
            this.openIframeModal=false
        },

        getSearchString() {
            return this.$store?.getters?.searchText;
        },

        showErrorModalFunc(message) {
            //this.$emit('error-modal', message)
        },
        showSuccessModalFunc(message) {
            //this.$emit('success-modal', message)
        },
    }
}
</script>

<style scoped>
.game_item_img {
    height: 35px;
    width: 35px;
    border-radius: 20px;
}
</style>