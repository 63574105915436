<template>
    <div class="casino-model-pop-btn">
        <div class="modal fade" id="sign-up-btn" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-body" style="padding-top: 0px !important;">
                        <button type="button" class="btn-close" data-bs-dismiss="modal">
                            <i class="fa-regular fa-xmark"></i>
                        </button>
                        <div class="sign-and-login-btn">
                            <div class="register-form">
                                <div v-if="siteSettings" class="logo-image">
                                    <img :src="siteSettings?.aws_url + siteSettings.storage_path.domain_image + siteSettings.logo"
                                        alt="logo">
                                </div>
                                <div class="login-step-title">Register</div>
                                <div class="whatsapp-btn">
                                    <div class="whattsup-title">Get your ready-made ID from whatsapp</div>
                                    <div class="signUpNew_icons"><img src="@/assets/images/diamond-upgrade-icn.png"
                                            alt=""><span>Diamond
                                            upgrade</span><img src="@/assets/images/customer-support-icn.png" alt=""><span>1
                                            to 1 customer support</span><img
                                            src="@/assets/images/instant-withdrawal-icn.png" alt=""><span>24/7
                                            instant withdrawal</span>
                                    </div>
                                    <div v-if="siteSettings" class="whatsapp-btn">
                                        <a :href="'//wa.me/' + siteSettings?.social_link.whatsappno1" target="_blank"
                                            v-if="siteSettings?.social_link && siteSettings?.social_link.whatsappno1"
                                            class="btn thm-but"><img style="position: absolute;"
                                                src="@/assets/images/whatsapp-icon.svg" alt="">
                                            <span class="blinking">Whatsapp
                                                Now</span></a>
                                    </div>
                                    <div class="signUpNew-separator">
                                        <div class="signUpNew-separator-rightLine"></div>
                                        <div class="signUpNew-separator-or">OR</div>
                                        <div class="signUpNew-separator-leftLine"></div>
                                    </div>
                                </div>
                                <div class="number-var mak-gin">
                                    <div class="row">
                                        <div class="col-3 col-sm-3 col-md-3">
                                            <select v-model="form.code" class="form-select form-control codein"
                                                id="autoSizingSelect">
                                                <option :value="allowedCountry.phonecode"
                                                    v-for="(allowedCountry, countryIndex) in siteSettings?.country_code_list"
                                                    :key="countryIndex">+{{ allowedCountry.phonecode }}</option>
                                            </select>
                                        </div>
                                        <div class="col-9 col-sm-9 col-md-9">
                                            <div class="whatsup-sec">
                                                <div class="input-form-sec input-left">
                                                    <input v-model="form.mobileNo" type="tel" class="form-control"
                                                        id="mobile" name="mobile" placeholder="10 Digit Phone Number*"
                                                        maxlength="10">
                                                </div>
                                            </div>
                                            <div v-if="showErrorMobile && form.mobileNo == ''"
                                                class="thm-heading justify-content-start mt-1">
                                                <p class="wrong-color">Please enter mobile number.</p>
                                            </div>
                                        </div>

                                    </div>

                                    <!-- <div class="col-12 mak-gin mo-number-right">
                                                        <p>{{ form.mobileNo.length }}/10</p>
                                                        <button @click="sendOtpCall()" class="btn cmn-bdr-btn otp-btn">Get
                                                            OTP</button>
                                                    </div> -->

                                    <div class="header-but justify-content-end mt-3">
                                        <span v-if="timerRunning" style="margin-right: 10px;color:white">{{
                                            formatTime(minutes) + ':' +
                                            formatTime(seconds) }}</span>
                                        <button :disabled="loadingOtp || timerRunning" type="button" @click="sendOtpCall()"
                                            class="btn cmn-bdr-btn otp-btn" :class="{ 'btn-loading': loadingOtp }">{{
                                                resendOtp ? 'Resend OTP' : 'Get OTP' }}
                                            <span><b></b><b></b><b></b></span></button>
                                    </div>

                                    <div class="input-form-sec" v-if="isOtpApiCalled">
                                        <input v-model="form.otp" type="number" class="form-control input-boxes" name="otp"
                                            id="otp-input" placeholder="OTP">
                                        <div v-if="showValidationError && getOtp().length < 6"
                                            class="thm-heading justify-content-start mt-1 ">
                                            <p class="wrong-color">Please enter OTP.</p>
                                        </div>
                                    </div>
                                    <!-- <span id="resend_time" class="otp-countdown-text text-danger">Resend
                                                            in <span id="timer"></span></span>
                                                        <span id="resend_button" class="otp-countdown-text d-none">
                                                            <a  class="text-success resend-btn float-end "
                                                                type="button"
                                                                onclick="sendMessageAgain('resend-forgot-otp')">
                                                                SEND SMS AGAIN
                                                            </a>
                                                        </span> -->

                                </div>

                                <div class="input-form-sec icon-input-bx">
                                    <i class="fa-solid fa-user"></i>
                                    <input type="text" v-model="form.userName" placeholder="User name" class="form-control">
                                    <div v-if="showValidationError && form.userName == ''"
                                        class="thm-heading justify-content-start mt-1">
                                        <p class="wrong-color">Please enter user name.</p>
                                    </div>
                                </div>

                                <div class="input-form-sec icon-input-bx">
                                    <i class="fa-solid fa-lock"></i>
                                    <input type="password" v-model="form.password" placeholder="Password"
                                        class="form-control">
                                    <div v-if="showValidationError && form.password == ''"
                                        class="thm-heading justify-content-start mt-1">
                                        <p class="wrong-color">Please enter password.</p>
                                    </div>
                                </div>
                                <div class="input-form-sec icon-input-bx">
                                    <i class="fa-solid fa-lock"></i>
                                    <input v-model="form.confirmPassword" type="password" placeholder="Confirm Password"
                                        class="form-control">
                                    <div class="thm-heading justify-content-start mt-1">
                                        <p v-if="showValidationError && form.confirmPassword == ''" class="wrong-color">
                                            Please enter confirm
                                            password.</p>
                                        <p v-if="passwordValidation" class="wrong-color">Password and confirm
                                            password not matched.
                                        </p>
                                    </div>
                                </div>

                                <Captcha v-if="isOtpApiCalled" :showValidationError="showValidationError" :form="form" />

                                <div class="login-and-reg-cmn-btn">
                                    <button @click="signUpCall()" class="login-cmn-btn">Register </button>
                                </div>
                                <div class="signUpNew_info">
                                    <span class="signUpNew_info-text">Already have account? </span>&nbsp;
                                    <a class="signUpNew_info-link" data-bs-toggle="modal" data-bs-target="#login-casino-btn"
                                        ref="openLoginModal">Log In</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

import api from '../services/api';
import * as apiName from '../services/urls';
import Captcha from './Captcha.vue';
import { Modal } from "bootstrap"
import captchaApi from '../services/captcha-api';


export default {
    name: 'SignUp',
    data() {
        return {
            form: {
                code: this.siteSettings && this.siteSettings?.country_code ? this.siteSettings?.country_code : "91",
                mobileNo: "",
                otp: "",
                userName: "",
                password: "",
                confirmPassword: "",
                referCode: this.getQueryParam("refer_code", window.location.href),
                captchaText: ''
            },
            showValidationError: false,
            showErrorMobile: false,
            passwordValidation: false,
            loadingSignUp: false,
            loadingOtp: false,
            isOtpApiCalled: false,
            resendOtp: false,
            timerRunning: false,
            minutes: 2,
            seconds: 0
        };
    },
    computed: {
        siteSettings() {
            return this.$store.getters.siteSettings;
        },
        domainName() {
            return this.$store.getters.domainName;
        },

    },
    mounted() {
        if (this.$route.name == 'sign-up' && !this.checkIsLogin()) {
            let loginModal = new Modal('#sign-up-btn');
            loginModal.show();
        }
        else if (this.$route.name == 'sign-up' && this.checkIsLogin()) {
            this.$router.push('/')
        }
    },
    methods: {
        refreshData() {
            this.form = {
                code: 91,
                mobileNo: "",
                otp: "",
                userName: "",
                password: "",
                confirmPassword: "",
                referCode: this.getQueryParam("refer_code", window.location.href),
                captchaText: ''
            },
                this.showValidationError = false,
                this.showErrorMobile = false,
                this.passwordValidation = false,
                this.isOtpApiCalled = false,
                this.resendOtp = false,
                this.resendOtpTimer = 0,
                this.timerRunning = false,
                this.minutes = 0,
                this.seconds = 0
        },
        sendOtpCall() {
            console.log("Loadin otp call...")
            if (!this.loadingOtp) {
                if (this.form.mobileNo == "") {
                    this.showErrorMobile = true;
                }
                else {
                    this.sendOtpServiceCall();
                }
            }
        },
        sendOtpServiceCall() {
            this.loadingOtp = true;
            let data = {
                "mobile_number": this.form.mobileNo,
                "cncode": this.form.code.toString(),
            };
            api.post(apiName.SEND_OTP, data).then(response => {
                console.log("Result : ", response);
                this.loadingOtp = false;
                if (response) {
                    if (response.status == 200) {
                        this.isOtpApiCalled = true;
                        this.form.captchaText = '';
                        this.$emit('success-modal', response.data.message);
                        window.scrollTo(0, 0);
                        this.resendOtp = true;
                        this.timerRunning = true;
                        this.minutes = 2;
                        this.seconds = 0
                        this.setResendTimer();
                    }
                    else if (response.status == 0) {
                    }
                }
            }).catch(error => {
                this.loadingOtp = false;
                this.$store.dispatch('setCaptchaData', null);
                captchaApi.getCaptchaDataServiceCall();
                console.log("Error : ", error);
            });
        },
        signUpCall() {
            if (!this.isOtpApiCalled)
                return
            if (!this.loadingSignUp) {
                let o = this.getOtp();
                this.passwordValidation = false;
                if (this.form.mobileNo == "") {
                    this.showErrorMobile = true;
                }
                else if (o.length != 6 || this.form.userName == '' || this.form.password == '' || this.form.confirmPassword == '' || this.form.captchaText == '') {
                    this.showValidationError = true;
                }
                else if (this.form.password != this.form.confirmPassword) {
                    this.passwordValidation = true;
                }
                else {
                    this.signUpServiceCall();
                }
            }
        },
        signUpServiceCall() {
            this.loadingSignUp = true;
            let isDownLine = this.getQueryParam("downline", window.location.href);
            let captchaData = this.$store.getters.captchaData;
            let data = {
                "userid": this.form.userName,
                "phone": this.form.mobileNo,
                "password": this.form.password,
                "otp": this.getOtp(),
                "isDownLine": isDownLine ? isDownLine : false,
                "refer_code": this.form.referCode,
                "captchaToken": this.form.captchaText,
                "captchaId": captchaData?.id,
                "cncode": this.form.code,
            };
            api.post(apiName.REGISTER, data).then(response => {
                console.log("Result : ", response);
                this.loadingSignUp = false;
                if (response) {
                    if (response.status == 200) {
                        console.log("response", response);
                        if (response.data.status == 0) {
                            this.$emit('error-modal', response.data.debug[0]);
                        }
                        else {
                            this.$emit('success-modal', response.data.message);
                            this.$refs.openLoginModal.click();
                            this.$store.dispatch('setCaptchaData', null);
                            captchaApi.getCaptchaDataServiceCall();
                            this.refreshData();
                        }
                    }
                }
            }).catch(error => {
                this.loadingSignUp = false;
                this.$store.dispatch('setCaptchaData', null);
                captchaApi.getCaptchaDataServiceCall();
                this.$emit('error-modal', error[0]);
            });
        },
        setResendTimer() {
            const countdown = () => {
                if (this.minutes === 0 && this.seconds === 0) {
                    this.timerRunning = false;
                    // Timer has expired, you can perform any action here
                } else {
                    if (this.seconds === 0) {
                        this.minutes--;
                        this.seconds = 59;
                    } else {
                        this.seconds--;
                    }
                    setTimeout(countdown, 1000);
                }
            };

            setTimeout(countdown, 1000);
        },
        formatTime(value) {
            return value < 10 ? `0${value}` : value;
        },
        getQueryParam(paramName, url) {
            if (!url) url = location.href;
            paramName = paramName.replace(/[\[]/, "\\\[").replace(/[\]]/, "\\\]");
            var regexS = "[\\?&]" + paramName + "=([^&#]*)";
            var regex = new RegExp(regexS);
            var results = regex.exec(url);
            return results == null ? null : results[1];
        },
        getOtp() {
            return this.form.otp.toString().replaceAll(',', '');
        },
        checkIsLogin() {
            return this.$store?.getters?.isAuthenticated;
        },

    },
    components: { Captcha }
}

</script>