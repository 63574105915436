<template>
    <div class="chatboat-modal modal fade" id="chatboat-modal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-body">
                    <div class="w-100 overflow-hidden position-relative">
                        <div class="p-3 p-lg-4 border-bottom user-chat-topbar">
                            <div class="align-items-center">
                                <div class="d-flex align-items-start">
                                    <div class="me-3 ms-0">
                                        <h4 class="mb-0 top-messages-title">Tiger</h4>
                                    </div>
                                    <div class="d-block me-2 ms-0">
                                        <button type="button" class="btn avatar-sm rounded-circle" data-bs-dismiss="modal">
                                            <span class="avatar-title bg-transparent font-size-20">
                                                <i class="fa-solid fa-xmark"></i>
                                            </span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="chat-conversation p-3 p-lg-4" data-simplebar="init">
                            <ul class="list-unstyled mb-0">
                                <li>
                                    <div class="conversation-list">
                                        <div class="chat-avatar">
                                            <div class="avatar-sm">
                                                <span class="avatar-title rounded-circle letter-wise-cricle">t</span>
                                            </div>
                                        </div>
                                        <div class="user-chat-content">
                                            <div class="ctext-wrap">
                                                <div class="ctext-wrap-content">
                                                    <p class="mb-0">Good morning</p>
                                                </div>
                                            </div>
                                            <div class="conversation-name"></div>
                                        </div>
                                    </div>
                                </li>
                                <li class="right">
                                    <div class="conversation-list">
                                        <div class="user-chat-content">
                                            <div class="ctext-wrap">
                                                <div class="ctext-wrap-content">
                                                    <p class="mb-0">Good morning, How are you? What about our next meeting?
                                                    </p>
                                                </div>
                                            </div>
                                            <div class="conversation-name"></div>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div class="conversation-list">
                                        <div class="chat-avatar">
                                            <div class="avatar-sm">
                                                <span class="avatar-title rounded-circle letter-wise-cricle">T</span>
                                            </div>
                                        </div>
                                        <div class="user-chat-content">
                                            <div class="ctext-wrap">
                                                <div class="ctext-wrap-content">
                                                    <p class="mb-0">Yeah everything is fine</p>
                                                </div>
                                            </div>
                                            <div class="ctext-wrap">
                                                <div class="ctext-wrap-content">
                                                    <p class="mb-0">& Next meeting tomorrow 10.00AM</p>
                                                </div>
                                            </div>
                                            <div class="conversation-name"></div>
                                        </div>
                                    </div>
                                </li>
                                <li class="right">
                                    <div class="conversation-list">
                                        <div class="user-chat-content">
                                            <div class="ctext-wrap">
                                                <div class="ctext-wrap-content">
                                                    <p class="mb-0">Wow that's great</p>
                                                </div>
                                            </div>
                                            <div class="conversation-name"></div>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div class="conversation-list">
                                        <div class="chat-avatar">
                                            <div class="avatar-sm">
                                                <span class="avatar-title rounded-circle letter-wise-cricle">T</span>
                                            </div>
                                        </div>
                                        <div class="user-chat-content">
                                            <div class="ctext-wrap">
                                                <div class="ctext-wrap-content">
                                                    <ul class="list-inline message-img mb-0">
                                                        <li class="list-inline-item message-img-list">
                                                            <div>
                                                                <div class="popup-img d-inline-block" title="Project 2">
                                                                    <img src="@/assets/images/game-2.jpg" alt=""
                                                                        class="rounded border" />
                                                                </div>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="conversation-name">19m ago.</div>
                                        </div>
                                    </div>
                                </li>
                                <li class="right">
                                    <div class="conversation-list">
                                        <div class="user-chat-content">
                                            <div class="ctext-wrap">
                                                <div class="ctext-wrap-content">
                                                    <div class="card p-2">
                                                        <div class="d-flex flex-wrap align-items-center attached-file">
                                                            <div class="avatar-sm me-3 ms-0 attached-file-avatar">
                                                                <div
                                                                    class="avatar-title letter-wise-cricle rounded font-size-20">
                                                                    <i class="fa-solid fa-file"></i>
                                                                </div>
                                                            </div>
                                                            <div class="flex-grow-1 overflow-hidden">
                                                                <div class="text-start">
                                                                    <h5 class="font-size-14 text-truncate mb-1">
                                                                        admin_v1.0.zip</h5>
                                                                    <p class="text-muted text-truncate font-size-13 mb-0">
                                                                        12.5 MB</p>
                                                                </div>
                                                            </div>
                                                            <div class="ms-4 me-0">
                                                                <div
                                                                    class="d-flex gap-2 font-size-20 d-flex align-items-start">
                                                                    <div>
                                                                        <a href="#" class="fw-medium">
                                                                            <i class="fa-solid fa-download"></i>
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="conversation-name">19m ago.</div>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div class="conversation-list">
                                        <div class="chat-avatar">
                                            <div class="avatar-sm">
                                                <span class="avatar-title rounded-circle letter-wise-cricle">T</span>
                                            </div>
                                        </div>
                                        <div class="user-chat-content">
                                            <div class="ctext-wrap">
                                                <div class="ctext-wrap-content typing">
                                                    <p class="chat-user-message text-truncate mb-0">
                                                        typing
                                                        <span class="animate-typing">
                                                            <span class="dot"></span>
                                                            <span class="dot"></span>
                                                            <span class="dot"></span>
                                                        </span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>


                        <div class="chat-input-section p-3 p-lg-4 border-top mb-0">
                            <div class="row g-0">
                                <div class="col">
                                    <div class="bottom-input-attach-btn">
                                        <div class="attach-btn chat-input-links">
                                            <ul class="cb-input-list">
                                                <li>
                                                    <div class="btn link-btn attached-file-bx">
                                                        <input class="form-control" type="file" id="formFile">
                                                        <i class="fa-solid fa-paperclip"></i>
                                                    </div>
                                                </li>
                                                <li>
                                                    <button type="button" class="btn link-btn">
                                                        <i class="fa-regular fa-face-smile"></i>
                                                    </button>
                                                </li>
                                            </ul>
                                        </div>
                                        <input type="text" class="form-control form-control-lg"
                                            placeholder="Write a reply...">
                                    </div>
                                </div>
                                <div class="col-auto">
                                    <div class="chat-send-btn ms-md-2 me-md-0">
                                        <button type="submit" class="btn cmn-btn">
                                            <i class="fa-solid fa-paper-plane"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'ChatModal'
}
</script>