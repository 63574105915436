<template>
    <div class="casino-model-pop-btn">
        <div class="modal fade" id="login-casino-btn" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-body" style="padding-top: 0px !important;">
                        <button ref="close_btn" @click="refreshData()" type="button" class="btn-close"
                            data-bs-dismiss="modal">
                            <i class="fa-regular fa-xmark"></i>
                        </button>
                        <div class="sign-and-login-btn">

                            <div class="register-form">
                                <div v-if="siteSettings" class="logo-image">
                                    <img :src="siteSettings?.aws_url + siteSettings.storage_path.domain_image + siteSettings.logo"
                                        alt="logo">
                                </div>
                                <!-- <div class="login-step-title">Log in</div> -->
                                <div class="login-tab-header">
                                    <ul class="nav nav-tabs">
                                        <li class="nav-item">
                                            <a class="nav-link active" data-bs-toggle="tab" href="#mobile-tab"><img
                                                    src="@/assets/images/otp.png"> Mobile No.</a>
                                        </li>
                                        <li class="nav-item">
                                            <a class="nav-link" data-bs-toggle="tab" href="#user-tab"><img
                                                    src="@/assets/images/user-tab.png"> User ID</a>
                                        </li>

                                    </ul>
                                </div>
                                <!--Login Form Start -->
                                <div class="login-form">
                                    <div class="input-form-sec">
                                        <div class="mak-gin text-danger">
                                            <div class="tab-content">
                                                <div class="tab-pane active" id="mobile-tab">
                                                    <div class="row">
                                                        <div v-if="form.type == 1" class="col-3 col-sm-3 col-md-3">
                                                            <select v-model="form.code"
                                                                class="form-select form-control codein"
                                                                aria-label="Default select example">
                                                                <option :value="allowedCountry.phonecode"
                                                                    v-for="(allowedCountry, countryIndex) in siteSettings?.country_code_list"
                                                                    :key="countryIndex">+{{ allowedCountry.phonecode }}
                                                                </option>
                                                            </select>
                                                        </div>
                                                        <div
                                                            :class="form.type == 1 ? 'col-9 col-sm-9 col-md-9' : 'col-9 col-sm-9 col-md-9'">
                                                            <div class="whatsup-sec">
                                                                <div class="input-left">
                                                                    <input v-if="form.type == 1" type="tel"
                                                                        class="form-control" id="mobile" maxlength="10"
                                                                        v-model="form.mobileNo"
                                                                        placeholder="Mobile Number*">
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <!-- <div class="col-3 col-sm-3 col-md-3">
                                                                <select :disabled="siteSettings?.business_type != 2" class="form-select form-control codein"
                                                                    aria-label="Default select example" v-model="form.type"
                                                                    @change="clickOnType()" id="countrycodewhatsapp">
                                                                    <option value="1" selected="">Phone</option>
                                                                    <option value="2">ID</option>
                                                                </select>
                                                            </div> -->
                                                        <!-- <input v-if="form.type == 2" type="text" class="form-control"
                                                                            id="mobile" v-model="form.userId"
                                                                            placeholder="Enter user id*"> -->
                                                    </div>
                                                </div>
                                                <div class="tab-pane fade" id="user-tab">
                                                    <div class="row">
                                                        <div
                                                            :class="form.type == 1 ? 'col-12 col-sm-12 col-md-12' : 'col-12 col-sm-12 col-md-12'">
                                                            <div class="whatsup-sec">
                                                                <div class="input-left">
                                                                    <!-- <input v-if="form.type == 2" type="text" class="form-control"
                                                                            id="mobile" v-model="form.userId"
                                                                            placeholder="Enter user id*"> -->
                                                                    <input type="text" class="form-control" id="mobile"
                                                                        v-model="form.userId" placeholder="Enter user id*">
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div v-if="showValidationError && getId() == ''"
                                                class="thm-heading justify-content-start mt-1">
                                                <p class="wrong-color">Please enter
                                                    {{ form.type == 1 ? 'mobile number' : 'user id' }}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="input-form-sec">
                                        <input v-model="form.password" type="password" placeholder="Your Password"
                                            class="form-control">
                                        <div v-if="showValidationError && form.password == ''"
                                            class="thm-heading justify-content-start mt-1">
                                            <p class="wrong-color">Please enter password.</p>
                                        </div>
                                    </div>

                                    <Captcha :showValidationError="showValidationError" :form="form" />

                                    <div class="forgot-password-link">
                                        <div class="forpass">
                                            <a data-bs-toggle="modal" data-bs-target="#forget-password-btn">Forgot
                                                Password?</a>
                                        </div>
                                    </div>
                                    <div class="login-and-reg-cmn-btn">
                                        <button @click="signInMethodCall()" class="login-cmn-btn">Log In</button>
                                    </div>
                                    <div class="login-and-reg-cmn-btn">
                                        <button @click="demoUserLogin()" class="login-cmn-btn">Demo Login</button>
                                    </div>
                                    <div class="registerWith-content">
                                        <div class="register-chose">Or Log in with</div>
                                        <!-- <div class="registerWith-content-btns">
                                                <a href="#" class="login-with-btn"><img src="@/assets/images/google-icon.svg"
                                                        alt=""></a>
                                                <a v-if="siteSettings && siteSettings?.social_link && siteSettings?.social_link.whatsappno1"
                                                :href="'//wa.me/' + siteSettings?.social_link.whatsappno1" target="_blank" class="login-with-btn"><img src="@/assets/images/whatsapp-icon.svg"
                                                        alt=""></a>
                                            </div> -->
                                        <div class="login-modal-social-link">
                                            <a href="#" target="_blank"><img src="@/assets/images/facebook-icon.svg"
                                                    alt=""></a>
                                            <a href="#" target="_blank"><img src="@/assets/images/google-icon.svg"
                                                    alt=""></a>
                                            <a href="#" target="_blank"><img src="@/assets/images/instagram.svg" alt=""></a>
                                            <a href="#" target="_blank"><img src="@/assets/images/telegram.svg" alt=""></a>
                                        </div>
                                    </div>
                                    <div class="registerWith-content">
                                        <div class="register-chose">Continue With Whatsapp</div>
                                        <div class="login-modal-social-link">
                                            <a v-if="siteSettings && siteSettings?.social_link && siteSettings?.social_link.whatsappno1"
                                                :href="'//wa.me/' + siteSettings?.social_link.whatsappno1" target="_blank"
                                                class="btn-whatsapp"><img src="@/assets/images/whatsapp.png" alt="">
                                                Whatsapp Now</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--Login Form End-->
                </div>
            </div>
        </div>
    </div>
</template>
<script>

import { AUTH_TOKEN } from '@/shared/constants/cookies-const';
import api from '../services/api';
import * as apiName from '../services/urls';
import axios from 'axios';
import { setHeaders } from '@/shared/services/headers';
import Captcha from './Captcha.vue';
import { Modal } from "bootstrap"
import captchaApi from '../services/captcha-api';


export default {
    name: 'Login',
    mounted() {
        console.log("Is login : ", this.$store?.getters?.isAuthenticated);
        console.log("User : ", this.$store?.getters?.stateUser);
        this.form.type = this.siteSettings?.business_type == 2 ? 1 : 2;
    },
    computed: {
        siteSettings() {
            return this.$store.getters.siteSettings
        }
    },
    data() {
        return {
            form: {
                code: 91,
                mobileNo: "",
                userId: "",
                password: "",
                type: 1,
                captchaText: ''
            },
            styleObject: {
                display: "none",
            },
            showValidationError: false,
            loading: false,
            demoLoading: false,
            modal: null,
        };
    },
    mounted() {
        if (this.$route.name == 'login' && !this.checkIsLogin()) {
            let loginModal = new Modal('#login-casino-btn');
            loginModal.show();
        }
        else if (this.$route.name == 'login' && this.checkIsLogin()) {
            this.$router.push('/')
        }
    },
    methods: {
        refreshData() {
            this.form = {
                code: 91,
                mobileNo: "",
                userId: "",
                password: "",
                type: 1,
                captchaText: ''
            },
                this.showValidationError = false
        },

        demoUserLogin() {
            this.form.type = 1;
            this.form.userId = "demouser";
            this.form.password = "demopass";
            this.form.captchaToken = "demoCaptchaToken";
            this.form.captchaId = "demoCaptchaId";
            this.loginServiceCall(apiName.DEMO_LOGIN_API, 'demo')
        },

        signInMethodCall() {
            if (!this.loading) {
                let id = this.getId();
                if (id == "" || this.form.password == "" || this.form.captchaText == "") {
                    this.showValidationError = true;
                }
                else {
                    this.loginServiceCall(apiName.LOGIN_API, 'nondemo');
                }
            }
        },
        loginServiceCall(url, type) {
            let id = this.getId();
            this.loading = true;
            let captchaData = this.$store.getters.captchaData;

            if (type == 'demo') {
                this.demoLoading = true;
            } else {
                this.loading = true;
            }

            let data = {
                "userid": id.toString(),
                "type": this.form.type,
                "password": this.form.password,
                "captchaToken": this.form.captchaText,
                "captchaId": captchaData.id,
                "cncode": this.form.code
            };
            api.post(url, data).then(response => {
                console.log("Result : ", response);
                this.loading = false;
                if (response) {
                    if (response.status == 200) {
                        captchaApi.getCaptchaDataServiceCall();
                        console.log("response", response);
                        if (response?.data?.status == 0) {
                            this.$emit('error-modal', response.data.debug[0]);
                        }
                        else {

                            this.$emit('success-modal', response.data.message);
                            let userData = response.data.data;
                            this.$cookies.set(AUTH_TOKEN, userData.token);
                            this.$store.dispatch('setToken', `Bearer ${userData.token}`);
                            axios.defaults.headers.common['Authorization'] = `Bearer ${this.$cookies.get(AUTH_TOKEN)}`;
                            window.authorization_token = `Bearer ${this.$cookies.get(AUTH_TOKEN)}`;
                            setHeaders();

                            this.setUserDataToStore(userData);
                            this.refreshData();
                            this.closeModal();
                            location.reload();
                        }
                    }
                }
            }).catch(error => {
                this.loading = false;
                captchaApi.getCaptchaDataServiceCall();
                if (error) {
                    console.log("Error Login : ", error[0]);
                    this.$emit('error-modal', error[0]);
                }
            });
        },
        clickOnType() {
            this.styleObject.display = "none";
            this.form.userId = "";
            this.form.mobileNo = "";
        },
        getId() {
            return this.form.type == 1 ? this.form.mobileNo : this.form.userId;
        },
        closeModal() {
            this.$refs.close_btn.click();
        },
        setUserDataToStore(userData) {
            let user =
            {
                balance: userData.balance,
                currency: userData.currency,
                currency_slug: userData.currency_slug,
                last_login_at: userData.last_login_at,
                name: userData.name,
                role_id: userData.role_id,
                userid: userData.userid,
                exposure: userData.exposure,
                available_credits: userData.available_credits
            }

            console.log("User : ", user);
            this.$store.dispatch('setUser', user);
            this.$store.dispatch('setChips', userData.chips);
        },
        checkIsLogin() {
            return this.$store?.getters?.isAuthenticated;
        },
    },
    components: { Captcha }
}
</script>
