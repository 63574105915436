import { createRouter, createWebHistory } from "vue-router";
const Home = () => import("../views/Home.vue");
const Login = () => import("@/modules/authorization/components/Login.vue");
const SignUp = () => import("@/modules/authorization/components/SignUp.vue");
const ForgetPassword = () => import("@/modules/authorization/components/ForgetPassword.vue");
import FavouriteRoutes from "@/modules/favourites";
import MatkaRoutes from "@/modules/matka";
import BetsRoutes from "@/modules/bets";
import StatementRoutes from "@/modules/statement";
import MyMarketRoutes from "@/modules/my-market";
import ReferEarnRoutes from "@/modules/refer-earn";
import BonusRoutes from "@/modules/bonus";
import RaceRoutes from "@/modules/race";
import GamesRoutes from "@/modules/games";
import WalletRoutes from "@/modules/wallet";
import SportsRoutes from "@/modules/sports";
import PoliciesRoutes from "@/modules/policies";
import SettingRoutes from "@/modules/setting";
import CricketFightRoutes from "@/modules/cricket-fight";

const routes = [
    {
      path: "/",
      name: "Home",
      component: Home
    },
     {
    path: "/login",
    name: "login",
    component: Login
  },
  {
    path: "/sign-up",
    name: "sign-up",
    component: SignUp
  },
  {
    path: "/forget-password",
    name: "forget-password",
    component: ForgetPassword
  },
    FavouriteRoutes,
    MatkaRoutes,
    BetsRoutes,
    StatementRoutes,
    MyMarketRoutes,
    ReferEarnRoutes,
    BonusRoutes,
    RaceRoutes,
    GamesRoutes,
    WalletRoutes,
    SportsRoutes,
    PoliciesRoutes,
    SettingRoutes,
    CricketFightRoutes
  ];
  
  
  export default createRouter({
    history: createWebHistory(),
    routes
  });