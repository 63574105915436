<template>
    <div class="casino-model-pop-btn">
        <div class="modal fade" id="forget-password-btn" tabindex="-1" aria-labelledby="exampleModalLabel"
            aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-body" style="padding-top: 0px !important;">
                        <button type="button" ref="close_btn" class="btn-close" data-bs-dismiss="modal">
                            <i class="fa-regular fa-xmark"></i>
                        </button>
                        <div class="sign-and-login-btn">
                            <div class="register-form">
                                <div class="logo-image">
                                    <img src="@/assets/images/Logo-lutos.png" alt="">
                                </div>
                                <div class="login-step-title">Forget Password</div>
                                <!--Forgot Password Form Start-->
                                <div class="forgot-password-form">
                                    <div class="number-var mak-gin">
                                        <div class="row">
                                            <div class="col-9 col-sm-9 col-md-9">
                                                <div class="whatsup-sec">
                                                    <div class="input-form-sec input-left">
                                                        <input type="tel" v-model="form.mobileNo" class="form-control"
                                                            id="mobile" name="mobile" maxlength="10"
                                                            placeholder="10 Digit Phone Number*">
                                                    </div>
                                                </div>
                                                <div v-if="showErrorMobile && form.mobileNo == ''"
                                                    class="thm-heading justify-content-start mt-1">
                                                    <p class="wrong-color">Please enter mobile number.</p>
                                                </div>
                                            </div>
                                            <div class="col-3 col-sm-3 col-md-3">
                                                <select v-model="form.code" class="form-select form-control codein"
                                                    id="autoSizingSelect">
                                                    <option :value="allowedCountry.phonecode"
                                                        v-for="(allowedCountry, countryIndex) in siteSettings?.country_code_list"
                                                        :key="countryIndex">+{{ allowedCountry.phonecode }}</option>
                                                </select>
                                            </div>
                                        </div>

                                        <!-- <Captcha
                                                            :showValidationError="showErrorMobile" :form="form"
                                                            @captcha-data="captchaDataUpdate" /> -->
                                        <div class="header-but justify-content-end mt-3">
                                            <span v-if="timerRunning" style="margin-right: 10px;color:white">{{
                                                formatTime(minutes) + ':' +
                                                formatTime(seconds) }}</span>
                                            <button :disabled="loadingOtp || timerRunning" type="button"
                                                @click="sendOtpCall()" class="btn cmn-bdr-btn otp-btn"
                                                :class="{ 'btn-loading': loadingOtp }">{{resendOtp ? 'Resend OTP' : 'Get OTP' }}
                                                <span><b></b><b></b><b></b></span></button>
                                        </div>



                                        <div class="input-form-sec">
                                            <input type="number" v-model="form.otp" class="form-control input-boxes"
                                                name="otp" id="otp-input" placeholder="OTP">

                                            <div v-if="showValidationError && getOtp().length < 6"
                                                class="thm-heading justify-content-start mt-1">
                                                <p class="wrong-color">Please enter OTP.</p>
                                            </div>
                                        </div>
                                        <!-- <span id="resend_time"
                                                                class="otp-countdown-text text-danger">Resend in <span
                                                                    id="timer"></span></span>
                                                            <span id="resend_button" class="otp-countdown-text d-none">
                                                                <a  class="text-success resend-btn float-end "
                                                                    type="button"
                                                                    onclick="sendMessageAgain('resend-forgot-otp')">
                                                                    SEND SMS AGAIN
                                                                </a>
                                                            </span> -->

                                    </div>
                                    <div class="input-form-sec icon-input-bx">
                                        <i class="fa-solid fa-lock"></i>
                                        <input v-model="form.password" type="password" placeholder="Password"
                                            class="form-control">
                                        <div v-if="showValidationError && form.password == ''"
                                            class="thm-heading justify-content-start mt-1">
                                            <p class="wrong-color">Please enter password.</p>
                                        </div>
                                    </div>
                                    <div class="input-form-sec icon-input-bx">
                                        <i class="fa-solid fa-lock"></i>
                                        <input v-model="form.confirmPassword" type="password" placeholder="Confirm Password"
                                            class="form-control">
                                        <div v-if="showValidationError && form.confirmPassword == ''"
                                            class="thm-heading justify-content-start mt-1">
                                            <p class="wrong-color">Please enter confirm password.</p>
                                        </div>
                                    </div>

                                    <Captcha v-if="isOtpApiCalled" :showValidationError="showValidationError"
                                        :form="form" />


                                    <div class="login-and-reg-cmn-btn">
                                        <button type="button" @click="forgetPasswordCall()" class="login-cmn-btn">Update
                                            Password </button>
                                    </div>

                                </div>
                                <!--Forgot Password Form End-->
                            </div>


                        </div>


                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import api from '../services/api';
import * as apiName from '../services/urls';
import Captcha from './Captcha.vue';
import { Modal } from "bootstrap"
import captchaApi from '../services/captcha-api';


export default {
    name: 'ForgetPassword',
    data() {
        return {
            form: {
                code: 91,
                mobileNo: "",
                otp: "",
                password: "",
                confirmPassword: "",
                captchaText: ''
            },
            loadingOtp: false,
            loadingUpdate: false,

            showValidationError: false,
            showErrorMobile: false,
            passwordValidation: false,
            isOtpApiCalled: false,
            resendOtp: false,
            timerRunning: false,
            minutes: 2,
            seconds: 0
        };
    },
    computed: {
        siteSettings() {
            return this.$store.getters.siteSettings
        }
    },
    mounted(){
        if(this.$route.name == 'forget-password' && !this.checkIsLogin()){
            let loginModal = new Modal('#forget-password-btn');
            loginModal.show();
        }
        else if(this.$route.name == 'forget-password' &&this.checkIsLogin()){
            this.$router.push('/')
        }
    },
    methods: {
        refreshData() {
            this.form = {
                code: 91,
                mobileNo: "",
                otp: "",
                password: "",
                confirmPassword: "",
                captchaText: ''
            },
                this.showValidationError = false,
                this.showErrorMobile = false,
                this.passwordValidation = false,
                this.isOtpApiCalled = false,
                this.resendOtp = false,
                this.resendOtpTimer = 0,
                this.timerRunning = false,
                this.minutes = 0,
                this.seconds = 0
        },
        sendOtpCall() {
            if (this.form.mobileNo == "" || this.form.captchaText == '') {
                this.showErrorMobile = true;
            }
            else {
                this.sendOtpServiceCall();
            }
        },
        sendOtpServiceCall() {
            this.loadingOtp = true;
            let data = {
                "mobile_number": this.form.mobileNo,
                "cncode": this.form.code,
                "getUserId": ""
            };
            api.post(apiName.SEND_OTP, data).then(response => {
                console.log("Result : ", response);
                this.loadingOtp = false;
                if (response) {
                    if (response.status == 200) {
                        console.log("response", response);
                        this.isOtpApiCalled = true;
                        this.form.captchaText = '';
                        this.$emit('success-modal', response.data.message);
                        this.resendOtp = true;
                        this.timerRunning = true;
                        this.minutes = 2;
                        this.seconds = 0
                        this.setResendTimer();
                    }

                }
            }).catch(error => {
                this.loadingOtp = false;
                this.$store.dispatch('setCaptchaData', null);
                captchaApi.getCaptchaDataServiceCall();
                if (error) {
                    console.log("Error : ", error);
                    this.$emit('error-modal', error[0]);
                }
            });
        },
        setResendTimer() {
            const countdown = () => {
                if (this.minutes === 0 && this.seconds === 0) {
                    this.timerRunning = false;
                    // Timer has expired, you can perform any action here
                } else {
                    if (this.seconds === 0) {
                        this.minutes--;
                        this.seconds = 59;
                    } else {
                        this.seconds--;
                    }
                    setTimeout(countdown, 1000);
                }
            };
            setTimeout(countdown, 1000);
        },
        formatTime(value) {
            return value < 10 ? `0${value}` : value;
        },
        forgetPasswordCall() {
            if (!this.isOtpApiCalled)
                return
            let o = this.getOtp();
            this.passwordValidation = false;
            if (this.form.mobileNo == "") {
                this.showErrorMobile = true;
            }
            else if (o.length != 6 || this.form.password == '' || this.form.confirmPassword == '' || this.form.captchaText == '') {
                this.showValidationError = true;
            }
            else if (this.form.password != this.form.confirmPassword) {
                this.passwordValidation = true;
            }
            else {
                this.forgotServiceCall();
            }
        },
        forgotServiceCall() {
            this.loadingUpdate = true;
            let captchaData = this.$store.getters.captchaData;
            let data = {
                "phone": this.form.mobileNo,
                "password": this.form.password,
                "confirm_password": this.form.confirmPassword,
                "otp": this.getOtp(),
                "captchaToken": this.form.captchaText,
                "captchaId": captchaData.id,
                "country_code": this.form.code,
            };
            api.post(apiName.FORGOT_PASSWORD, data).then(response => {
                console.log("Result : ", response);
                this.loadingUpdate = false;
                this.$store.dispatch('setCaptchaData', null);
                captchaApi.getCaptchaDataServiceCall();
                if (response) {

                    if (response.status == 200) {
                        console.log("response", response);
                        if (response.data.status == 0) {
                            this.$emit('error-modal', response.data.debug[0]);
                        }
                        else {
                            this.$emit('success-modal', response.data.message);
                            this.refreshData();

                            this.closeModal();
                        }
                    }
                }
            }).catch(error => {
                this.loadingUpdate = false;
                this.$store.dispatch('setCaptchaData', null);
                captchaApi.getCaptchaDataServiceCall();
                if (error) {
                    this.$emit('error-modal', error[0]);
                }
            });
        },
        getOtp() {
            return this.form.otp.toString().replaceAll(',', '');
        },
        closeModal() {
            this.$refs.close_btn.click();
        },
        checkIsLogin() {
            return this.$store?.getters?.isAuthenticated;
        },

    },
    components: { Captcha }
}

</script>