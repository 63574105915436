<template>

    <div v-if="captchaData" class="input-form-sec">
        <input v-model="form.captchaText" type="tel" maxlength="4" placeholder="Enter captcha" class="form-control">
        <span class="captcha-code" v-if="captchaData">
            <img :src="captchaData?.imageUri" alt="">
        </span>
        <div v-if="showValidationError && form.captchaText == ''" class="thm-heading justify-content-start mt-2">
            <p class="wrong-color">Please enter captcha.</p>
        </div>
    </div>
</template>

<script>

import { mapGetters } from 'vuex';

export default {
    name: 'Captcha',
    props: ['showValidationError', 'form'],
    data() {
        return {
            loading: false,
            intervalid1: null
        }
    },
    computed: {
        ...mapGetters({
            captchaData: 'captchaData'
        })
    },
    beforeUnmount() {
        this.$store.dispatch('setCaptchaData', null);
    },
    methods: {
        checkIsLogin() {
            return this.$store.getters.isAuthenticated;
        },
        showErrorModalFunc(message) {
            this.$emit('error-modal', message);
        },
        showSuccessModalFunc(message) {
            this.$emit('success-modal', message);
        }
    }
}
</script>

<style scoped>
.captcha-code {
    position: absolute;
    right: 0;
    top: 7px;
    background: var(--secondary-color);
    height: 30px;
    width: 60px;
    text-align: center;
    border-radius: 4px;
}
.captcha-code img {
    margin-left: 10px;
}
</style>