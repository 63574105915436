<template>
    <div  class="edit-stack-details" v-if="chips">
                    <div class="bg-light">
                        <div class="stack-page-container">
                            <div class="stack-page-items">
                                <input type="number" @change="chips.chip_name_1 = getKFormatter(chips.chip_val_1)"
                                        v-model="chips.chip_val_1" >
                            </div>
                            <div class="stack-page-items">
                                <input type="number" @change="chips.chip_name_2 = getKFormatter(chips.chip_val_2)"
                                        v-model="chips.chip_val_2" >
                            </div>
                            <div class="stack-page-items">
                                <input type="number" @change="chips.chip_name_3 = getKFormatter(chips.chip_val_3)"
                                        v-model="chips.chip_val_3" >
                            </div>
                            <div class="stack-page-items">
                                <input type="number" @change="chips.chip_name_4 = getKFormatter(chips.chip_val_4)"
                                        v-model="chips.chip_val_4" >
                            </div>
                            <div class="stack-page-items">
                                <input type="number" @change="chips.chip_name_5 = getKFormatter(chips.chip_val_5)"
                                        v-model="chips.chip_val_5" >
                            </div>
                            <div class="stack-page-items">
                                <input type="number" @change="chips.chip_name_6 = getKFormatter(chips.chip_val_6)"
                                        v-model="chips.chip_val_6" >
                            </div>
                            <div class="stack-page-items">
                                <input type="number" @change="chips.chip_name_7 = getKFormatter(chips.chip_val_7)"
                                        v-model="chips.chip_val_7" >
                            </div>
                            <div class="stack-page-items">
                                <input type="number" @change="chips.chip_name_8 = getKFormatter(chips.chip_val_8)"
                                        v-model="chips.chip_val_8" >
                            </div>

                            
                        </div>
                        <div class="cancail-and-save-sec">
                            <div class="cancail">
                                <button type="button" ref="close_button" @click="$emit('show-edit-stake-false')" id="cancail-btn">Cancel</button>
                            </div>
                            <div class="save-btn">
                                <button @click="callChangeStakeValue()" type="button">Save</button>
                            </div>
                        </div>
                    </div>
                </div>
</template>
<script>

import { AUTH_TOKEN } from '@/shared/constants/cookies-const';
import api from '../../services/api';
import * as apiName from '../../services/urls';
import { kFormatter } from '@/shared/utils/formatter';


export default{
    name:'StakeModal',
    data() {
        return {
            chips: null,
            chipsDummy: null,
            userData: null
        }
    },
    mounted() {
        this.showSuccessModalFunc('get bot')
        this.chips = this.$store?.getters?.chips;
        this.chipsDummy = this.$store?.getters?.chips;
        this.userData = this.$store?.getters?.stateUser;
        console.log("Chips : ", this.chips);
    },
    methods: {
        callChangeStakeValue() {
            let chipsData = {
                "chips": {
                    // "id": this.userData?.id,
                    // "user_id": this.userData?.userid,
                    ...this.chips
                }
            }
            let headers = {
                'Authorization': `Bearer ${this.$cookies.get(AUTH_TOKEN)}`
            }
            console.log("Chips Data : ", chipsData);

            this.loading = true;
            api.post(apiName.CHANGE_STAKE_VALUES, chipsData, { headers }).then(response => {
                this.loading = false;
                if (response && response.status == 200) {
                    this.$store.dispatch('setChips', this.chips);
                    this.showSuccessModalFunc(response?.data?.message);
                    this.$refs.close_button.click();
                } else {

                }
            }).catch(error => {
                this.loading = false;
                if (error) {
                    this.showErrorModalFunc(error[0]);
                }
            });
        },
        getKFormatter(num) {
            return kFormatter(num);
        },
        showErrorModalFunc(message) {
            this.$emit('error-modal', message);
        },
        showSuccessModalFunc(message) {
            this.$emit('success-modal', message);
        }
    },

    emits:['showEditStake']

}
</script>