<template>
    <div v-if="showLoader" class="divLoader"><img alt="logo" class="loader-img" id="logoImage" src="@/assets/images/lotus-world.png"><div class="spinner"></div></div>
        <div class="latest-news-marquee desktop-header-marquee d-none">
            <div class="news">
                <marquee class="news-content">
                    <p>The September 11 attacks, as well as the September 11, 2001 attacks or the September 11 attacks,
                        known
                        as: 9/11; It is a group of attacks that targeted the United States on Tuesday, September 11, 2001,
                        and
                        was
                        carried out by four commercial civilian transport planes, led by four teams affiliated with
                        Al-Qaeda,
                        directed to hit specific targets
                    </p>
                </marquee>
            </div>
        </div>
        <div class="latest-news-marquee mobile-header-marquee d-none" id="mobile-header-marquee">
            <div class="news">
                <marquee class="news-content">
                    <p>The September 11 attacks, as well as the September 11, 2001 attacks or the September 11 attacks,
                        known
                        as: 9/11; It is a group of attacks that targeted the United States on Tuesday, September 11, 2001,
                        and
                        was
                        carried out by four commercial civilian transport planes, led by four teams affiliated with
                        Al-Qaeda,
                        directed to hit specific targets
                    </p>
                </marquee>
                <a id="marquee-close"><i class="fa-solid fa-xmark"></i></a>
            </div>
        </div>
        <div class="top-header">
            <div class="container">
                <div class="row">
                    <div class="col-6 col-sm-6 col-md-4 col-lg-4">
                        <div class="cmn-lft-logo">
                            <ul class="cmn-ul-list">
                                <li v-if="!backBtnPages.includes($route.name)" @click="$router.back()" class="back-arrow">
                                    <span><i class="fa-solid fa-arrow-left"></i></span>
                                </li>
                                <li v-else class="sidebar-nav-toggle nav-custom">
                                    <span @click="$emit('open-side-nav')"><i class="fa-sharp fa-solid fa-bars"></i></span>
                                </li>
                                <li v-if="siteSettings">
                                    <router-link to="/"><img class="logo-img"
                                            :src="siteSettings.aws_url + siteSettings.storage_path.domain_image + siteSettings.logo"
                                            alt="logo"></router-link>
                                            
                                </li>
                                <li class="urrent-dt-li">
                                    <div class="current-dt"><span>{{ currentDate }}</span><strong>{{ currentTime }}</strong>
                                        <span class="country-standred-time">&nbsp;({{ currentLocal }})</span>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-6 col-sm-6 col-md-4 toop-search-col">
                        <div class="top-search-form">
                            <form role="search">
                                <div class="d-flex Search-bar-sec">
                                    <div class="icon"><img src="@/assets/images/search-icon.svg" alt="search-icon"></div>
                                    <input class="form-control " :value="getSearchText()" @input="onChange($event)"
                                        type="search" placeholder="Search Events" aria-label="Search">
                                </div>
                            </form>
                            <div class="desktop-auto-search-sec">
                                <SearchList ref="childRef" />
                            </div>


                        </div>

                        <div v-if="checkIsLogin() && userData" class="headerDesktop-content__loged">
                            <div class="headerDesktop-content__loged-loginName">Logged in as {{ userData?.name }}</div>
                            <div class="headerDesktop-content__lastloged">Last logged in: {{
                                timeConverter(userData?.last_login_at) }}
                            </div>
                        </div>

                    </div>
                    <div v-if="!checkIsLogin()" class="col-6 col-sm-6 col-md-4">
                        <div class="top-rit-nav">
                            <ul class="cmn-ul-list desktop-nav-rit">
                                <li class="login-btn">
                                    <a class="link" data-bs-toggle="modal" data-bs-target="#login-casino-btn">Log in</a>
                                </li>
                                <li class="sign-up-btn">
                                    <a class="link" data-bs-toggle="modal" data-bs-target="#sign-up-btn">Sign up</a>
                                </li>
                            </ul>
                            <ul class="cmn-ul-list mobile-nav-rit">
                                <li class="login-btn">
                                    <a class="link" href="#login-casino-btn" data-bs-toggle="modal">Log in</a>
                                </li>
                                <li class="sign-up-btn" style="margin-left: 2px;">
                                    <a class="link" href="#sign-up-btn" data-bs-toggle="modal">Sign up</a>
                                </li>
                            </ul>
                        </div>

                    </div>

                    <div v-if="checkIsLogin()" class="col-6 col-sm-6 col-md-4">
                        <div class="top-rit-nav">
                            <ul class="cmn-ul-list">
                                <li class="deposit-btn">
                                    <a @click="getWalletGateway()"
                                        class="btn btn-primary">Deposit</a>
                                </li>
                                <li class="Withdrawal-btn">
                                    <a @click="getWalletGateway()" class="btn btn-primary">Wallet</a>
                                </li>
                                <li class="my-account-sidebar-nav" style="margin-left: 2px;">
                                    <span @click="$emit('open-mobile-sidebar')"><i
                                            class="fa-solid fa-user"></i><strong>{{ getBalance() }}</strong></span>
                                </li>
                                <li class="my-account-desktop">
                                    <div class="dropdown menu acc-section">
                                        <button class="btn link-btn dropdown-toggle" type="button" id="dropdownMenu2"
                                            data-bs-toggle="dropdown" aria-expanded="false">
                                            <i class="fa-solid fa-gear"></i> Account
                                        </button>
                                        <ul class="dropdown-menu list" aria-labelledby="dropdownMenu2">
                                            <li><router-link :to="{ name: 'wallet-history' }"
                                                    class="dropdown-item">Transactions</router-link></li>

                                            <li><a data-bs-toggle="modal" data-bs-target="#language_selection_pop_up"
                                                    class="dropdown-item">Language</a></li>
                                            <li><router-link :to="{ name: 'bets' }" class="dropdown-item">Open
                                                    Bets</router-link></li>
                                            <li><router-link :to="{ name: 'win-loss' }" class="dropdown-item">Betting Profit
                                                    and Loss</router-link></li>
                                            <li><router-link to="bonus" class="dropdown-item">Deposit Bonus</router-link>
                                            </li>
                                            <li><router-link to="refer-earn" class="dropdown-item">Bonuses</router-link>
                                            </li>
                                            <li><router-link :to="{ name: 'statement', params: { type: 'ACCOUNT' } }"
                                                    class="dropdown-item">Account Statement</router-link></li>
                                            <li><router-link :to="{ name: 'statement', params: { type: 'TRANSFER' } }"
                                                    class="dropdown-item">Transfer Statement</router-link></li>
                                            <li><router-link :to="{ name: 'change-password' }" class="dropdown-item">Change
                                                    Password</router-link></li>
                                            <li><router-link
                                                    :to="{ name: 'policies', params: { type: 'RulesRegulations' } }"
                                                    class="dropdown-item">Rules & Regulations</router-link></li>
                                            <li><router-link :to="{ name: 'setting' }"
                                                    class="dropdown-item">Settings</router-link></li>
                                            <li><router-link :to="{ name: 'policies', params: { type: 'ExclusionPolicy' } }"
                                                    class="dropdown-item">Exculsion policy</router-link></li>
                                            <li><router-link
                                                    :to="{ name: 'policies', params: { type: 'ResponsibleGaming' } }"
                                                    class="dropdown-item">Responsilbe Gaming</router-link></li>
                                            <li><router-link :to="{ name: 'policies', params: { type: 'PrivacyPolicy' } }"
                                                    class="dropdown-item">Privacy Policy</router-link></li>
                                        </ul>
                                    </div>
                                </li>
                                <li class="logout-desktop">
                                    <a class="link logout-btn" data-bs-toggle="modal" data-bs-target="#logoutmodal">
                                        <i class="bi bi-box-arrow-right"></i><i class="fa-solid fa-right-from-bracket"></i>
                                        Logout</a>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div class="col-12 mobile-search-col">
                        <div class="apk-icon-bx">
                            <a href="#install-apk-model" data-bs-toggle="modal">
                                <img src="@/assets/images/apk-icon-white.svg" alt="" />
                            </a>
                        </div>
                        <div v-if="checkIsLogin()" class="mobile-search-bx">
                            <div class="input-box" :class="{ 'open': mobileSearch }">
                                <input :value="getSearchText()" @input="onChange($event)" type="search"
                                    placeholder="Search Events" />
                                <span v-if="checkIsLogin()" @click="mobileSearch = !mobileSearch" class="search">
                                    <i class="fa-solid fa-magnifying-glass search-icon"></i>
                                </span>
                                <i @click="mobileSearch = !mobileSearch" class="fa-solid fa-xmark close-icon"></i>
                            </div>
                        </div>
                    </div>


                </div>
            </div>

            <div v-if="mobileSearch">
                <div class="mobile-auto-search-sec">
                    <SearchList ref="childRef" />
                </div>

            </div>
        </div>
        <div class="header-menu">
            <NavBar />
        </div>

    
    <LogoutModal @error-modal="showErrorModalFunc" @success-modal="showSuccessModalFunc" />
    <DownloadApkModal></DownloadApkModal>

</template>

<script>
import moment from 'moment';
import LogoutModal from './LogoutModal.vue';
import NavBar from './NavBar.vue';
import SearchList from './SearchList.vue';
import { ref } from 'vue';
import { getWalletGetwayServiceCall } from '@/shared/services/wallet-service';
import DownloadApkModal from '@/shared/components/modal/DownloadApkModal.vue';


export default {
    name: 'Header',
    setup() {
        const childRef = ref(null);
        const callChildFunction = () => {
            childRef.value.searchCasinoMatches();
        };

        return {
            childRef,
            callChildFunction,
        };
    },
    computed: {
        siteSettings() {
            return this.$store.getters.siteSettings
        },
        formatNumber() {
            return (value) => {
                return parseFloat(value).toFixed(2);
            }
        }
    },
    data() {
        return {
            isLogin: this.$store?.getters?.isAuthenticated,
            userData: null,
            currentDate: null,
            currentTime: null,
            currentLocal: null,
            showLoader: true,
            mobileSearch: false,
            backBtnPages:['Home','CricketFight','MatchDetails','PlaceBet','MyContests','MyContestBetDetails'],
        }
    },
    mounted() {
        this.userData = this.$store?.getters?.stateUser;
        console.log("this.userData : ", this.userData);
        this.setTime();
        setTimeout(() => {
            this.showLoader = false;
        }, 1000);
    },
    emits: ['call-search-api'],
    methods: {
        checkIsLogin() {
            return this.$store?.getters?.isAuthenticated;
        },
        setTime() {
            this.currentDate = moment(new Date()).format('DD MMM YYYY');
            this.timerFunc();
            setInterval(this.timerFunc, 1000);
            this.currentLocal = new Date().getTimezoneOffset();
            this.currentLocal = ((this.currentLocal < 0 ? '+' : '-') + // Note the reversed sign!
                this.pad(parseInt(Math.abs(this.currentLocal / 60)), 2) + ':' +
                this.pad(Math.abs(this.currentLocal % 60), 2))
        },
        timerFunc() {
            this.currentTime = moment(new Date()).format('HH:mm:ss');
        },
        pad(number, length) {
            var str = "" + number
            while (str.length < length) {
                str = '0' + str
            }
            return str
        },
        getWalletGateway() {
            getWalletGetwayServiceCall();
        },
        getSearchText() {
            return this.$store?.getters?.searchText;
        },
        getBalance(){
            return this.formatNumber(parseFloat(this.userData?.balance)+parseFloat(this.userData?.exposure?this.userData?.exposure:0));
        },
        onChange(event) {
      
                let search = event.target.value;
                this.$store.dispatch('setSearchText', search);
                this.callChildFunction();

        },
        timeConverter(timestamp) {
            return moment(timestamp).format('DD/MM/YYYY HH:mm A');
        },
        showErrorModalFunc(message) {
            this.$emit('error-modal', message)
        },
        showSuccessModalFunc(message) {
            this.$emit('success-modal', message)
        }

    },
    components: {
        NavBar,
        LogoutModal,
        SearchList,
        DownloadApkModal
    },
    emits: ['open-side-nav', 'open-mobile-sidebar']
}



</script>