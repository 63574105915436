<template>
    <div class="bonus-wallet-modal-sec">
        <div class="bonus-wallet-tnc-modal modal fade" id="bonus-wallet-tnc-modal" tabindex="-1"
            aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel"><img src="@/assets/images/bonus-info-icon.svg"
                                alt="" /><span>Bonus Information</span></h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="bw-tnc-content">
                            <div class="intro-and-rule-sec">
                                <h3>THE SITE RULES AND REGULATIONS</h3>
                            </div>
                            <div class="intro-section">
                                <h4>PART A - INTRODUCTION</h4>
                            </div>
                            <div class="intro-text-heading">
                                <h5>1. Use and interpretation</h5>
                            </div>
                            <div class="text-details">
                                <p>These Rules and Regulations (<strong>"Rules"</strong>) are part of the Site's terms and
                                    conditions.
                                </p>
                                <p>The Rules apply to all bets placed on this online betting platform
                                    ("<strong>"Sites"</strong>"). The
                                    Rules consist of the following:</p>
                            </div>
                            <div class="general-rule-sec">
                                <ul>
                                    <li>
                                        This INTRODUCTION section (Part A);
                                    </li>
                                    <li>
                                        The GENERAL RULES (set out in Part B below); and
                                    </li>
                                    <li>
                                        The SPECIFIC SPORTS RULES (set out in Part C below - these apply to certain sports).
                                    </li>
                                </ul>
                            </div>
                            <div class="text-details">
                                <p>The General Rules apply to all bets unless stated otherwise in the Specific Sports Rules.
                                    If there is
                                    any inconsistency between the Specific Sports Rules and the General Rules, the Specific
                                    Sports Rules
                                    shall prevail.</p>
                                <p>The rules governing how markets are offered, managed and/or settled are not the same for
                                    every market
                                    on each product. In certain circumstances, a bet that is settled as a winner on one
                                    product may be
                                    settled as a loser on the other product (and vice versa). Additionally, different
                                    settlement rules may
                                    apply so that, for example, bets that are a winner on one product may be settled as a
                                    dead heat or be
                                    voided on the other product. Customers must ensure that they familiarise themselves with
                                    the relevant
                                    rules that apply to the bets that they place on the Site.</p>
                            </div>
                            <div class="intro-text-heading">
                                <h5>2. Customer responsibility</h5>
                            </div>
                            <div class="general-rule-sec">
                                <ul>
                                    <li>
                                        Customers should make themselves aware of all of the Rules affecting any market on
                                        which they wish
                                        to place a bet.
                                    </li>
                                    <li>
                                        In particular, customers who use the "one-click" option to place bets are solely
                                        responsible for
                                        their actions and the Site shall have no liability to such customers for any errors
                                        made by
                                        customers when using this option.
                                    </li>
                                </ul>
                            </div>
                            <div class="intro-section">
                                <h4>PART B - GENERAL RULES</h4>
                            </div>
                            <div class="intro-text-heading">
                                <h5>1. Matters beyond the Site's reasonable control and malfunctions</h5>
                            </div>
                            <div class="text-details">
                                <p>The Site is not liable for any loss or damage you may suffer because of any: act of God;
                                    power cut;
                                    trade or labour dispute; act, failure or omission of any government or authority;
                                    obstruction or
                                    failure of telecommunication services; or any other delay or failure caused by a third
                                    party or
                                    otherwise outside of our control. In such an event, the Site reserves the right to
                                    cancel or suspend
                                    access to the Site without incurring any liability.</p>
                                <p>The Site is not liable for the failure of any equipment or software howsoever caused,
                                    wherever
                                    located or administered, or whether under its direct control or not, that may prevent
                                    the operation of
                                    the Site.</p>
                                <p>In the event of a technological failure or error which is apparent to the customer, the
                                    customer is
                                    obliged to notify the Site of such failure/error immediately. If the customer continues
                                    to place a bet
                                    in these circumstances, they shall take reasonable action to minimise any potential
                                    loss. In the
                                    absence of such action, the Site reserves the right to void a bet.</p>
                                <p>The Site reserves the right in its absolute discretion to restrict access to the Site, or
                                    withhold
                                    funds or void any bets outstanding to a customer’s account in its absolute discretion in
                                    the event of
                                    a technological failure or other malfunction which affects the integrity of the Site
                                    whether this is
                                    under its direct control or otherwise. Customers will be notified on the Site of any
                                    such malfunction
                                    which may operate to prevent the placing of further bets or which may result in
                                    outstanding bets being
                                    voided.</p>
                            </div>
                            <div class="intro-text-heading">
                                <h5>2. Managing markets In-Play</h5>
                                <p>General</p>
                            </div>
                            <div class="general-rule-sec">
                                <ul>
                                    <li>
                                        For everything other than horseracing and greyhound racing, if a market is not
                                        scheduled to be
                                        turned in-play but the Site fails to suspend the market at the relevant time, then:
                                        <ol type="i">
                                            <li>if the event has a scheduled 'off' time, all bets matched after that
                                                scheduled off time will
                                                be void; and</li>
                                            <li>if the event does not have a scheduled 'off' time, the Site will use its
                                                reasonable endeavours
                                                to ascertain the time of the actual 'off' and all bets after the time of the
                                                'off' determined by
                                                the Site will be void.</li>
                                        </ol>
                                    </li>
                                    <li>
                                        For horseracing and greyhound racing, if a market is not scheduled to be turned
                                        in-play but the Site
                                        fails to suspend the market at the relevant time, then all bets matched after the
                                        official 'off'
                                        time will be void.
                                    </li>
                                    <li>
                                        The Site aims to use its reasonable endeavours to suspend in-play markets at the
                                        start of and at the
                                        end of the event. However, the Site does not guarantee that such markets will be
                                        suspended at the
                                        relevant time.
                                    </li>
                                    <li>
                                        Customers are responsible for managing their in-play bets at all times.
                                    </li>
                                    <li>
                                        For the purposes of in-play betting, customers should be aware that transmissions
                                        described as
                                        "live" by some broadcasters may actually be delayed or pre-recorded. The extent of
                                        any delay may
                                        vary depending on the set-up through which they are receiving pictures or data.
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="bonus-wallet-tnc-back">
                            <button type="button" class="btn link-btn" data-bs-toggle="modal"
                                data-bs-target="#bonus-wallet-modal">
                                <div class="backArrow"><img src="@/assets/images/arrow-open-pop.svg" alt="Go Back">Go Back
                                </div>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

export default{
    name:'BonusWalletTnc'
}

</script>