<template>
    <div class="sidebar-menu">
        <ul>
            <li class="details-item cmn-ac-info">
                <strong class="ac-user-name">{{ userData?.name }}</strong>
            </li>
            <li class="details-item cmn-ac-info">
                <div class="details-link ac-nav-link">
                    <i class="fa-solid fa-building-columns"></i>
                    <span class="menu-title">Balance Information</span>
                </div>
                <div class="right-side-menu-account-info">
                    <div class="ac-info-item">
                        <span class="ac-info-label">Balance :</span>
                        <span class="ac-info-value">{{ userData?.balance }}</span>
                    </div>
                    <div  class="ac-info-item">
                        <span class="ac-info-label">Net Exposure:</span>
                        <span class="ac-info-value" :class="userData?.exposure>0?'net-exposure-bi':''">{{ userData?.exposure }}</span>
                    </div>
                </div>
                <div class="dw-bets-sec">
                    <ul class="cmn-ul-list">
                        <li class="betslip-btn">
                            <button class="btn btn-primary" @click="getWalletGateway()"
                                type="button">Deposit</button>
                        </li>
                        <li class="betslip-btn">
                            <button class="btn btn-primary" @click="getWalletGateway()"
                                type="button">Wallet</button>
                        </li>
                    </ul>
                </div>
            </li>
            <!-- <li class="details-item cmn-ac-info bonus-information-bxz">
                <div class="details-link ac-nav-link">
                    <i class="fa-solid fa-gift"></i>
                    <span class="menu-title">Bonus Information</span>
                    <button type="button" class="btn link-btn" data-bs-toggle="modal"
                        data-bs-target="#bonus-wallet-modal">i</button>
                </div>
                <div class="right-side-menu-account-info">
                    <div class="ac-info-item">
                        <span class="ac-info-label">Available Credit:</span>
                        <span class="ac-info-value">{{ userData?.available_credits }}</span>
                    </div>
                    <div class="ac-info-item">
                        <span class="ac-info-label">Net Exposure:</span>
                        <span class="ac-info-value"  :class="userData?.exposure>0?'net-exposure-bi':''" >{{ userData?.exposure }}</span>
                    </div>
                    <div class="ac-info-item play-with-bonus">
                        <span class="ac-info-label">Play with Bonus</span>
                        <span class="ac-info-value">
                            <div class="form-check form-switch">
                                <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault">
                            </div>
                        </span>
                    </div>
                </div>
            </li> -->
            <li class="details-item">
                <router-link @click="$emit('close-mobile-sidebar')" :to="'/bets'" class="details-link ac-nav-link">
                    <i class="fa-solid fa-list"></i>
                    <span class="menu-title">Open Bets</span>
                </router-link>
            </li>
            <li class="details-item">
                <router-link :to="'/win-loss'" @click="$emit('close-mobile-sidebar')" class="details-link ac-nav-link">
                    <i class="fa-solid fa-money-bill-1"></i>
                    <span class="menu-title">Betting Profit & Loss</span>
                </router-link>
            </li>
            <li class="details-item">
                <router-link :to="'/statement/ACCOUNT'" @click="$emit('close-mobile-sidebar')"
                    class="details-link ac-nav-link">
                    <i class="fa-solid fa-clipboard-list"></i>
                    <span class="menu-title">Account Statement</span>
                </router-link>
            </li>
            <!-- <li class="details-item">
                <router-link :to="'/open-bets'" @click="$emit('close-mobile-sidebar')" class="details-link ac-nav-link">
                    <i class="fa-solid fa-tags"></i>
                    <span class="menu-title">Bonus Statement</span>
                </router-link>
            </li> -->
            <li class="details-item">
                <router-link :to="'/statement/TRANSFER'" @click="$emit('close-mobile-sidebar')"
                    class="details-link ac-nav-link">
                    <i class="fa-solid fa-money-bill-transfer"></i>
                    <span class="menu-title">Transfer Statement</span>
                </router-link>
            </li>
            <li class="details-item">
                <router-link :to="'/time-setting'" @click="$emit('close-mobile-sidebar')" class="details-link ac-nav-link">
                    <i class="fa-solid fa-clock"></i>
                    <span class="menu-title">Time Setting</span>
                </router-link>
            </li>
            <li class="details-item">
                <router-link :to="'/change-password'" @click="$emit('close-mobile-sidebar')"
                    class="details-link ac-nav-link">
                    <i class="fa-solid fa-lock"></i>
                    <span class="menu-title">Change Password</span>
                </router-link>
            </li>
            <li class="details-item">
                <router-link :to="'policies/RulesRegulations'" @click="$emit('close-mobile-sidebar')"
                class="details-link ac-nav-link">
                <i class="fa-solid fa-clipboard-list"></i>
                <span class="menu-title">Rules & Regulations</span>
                </router-link>
            </li>
            <li class="details-item">
                <router-link :to="'/setting'" @click="$emit('close-mobile-sidebar')" class="details-link ac-nav-link">
                    <i class="fa-solid fa-gear"></i>
                    <span class="menu-title">Settings</span>
                </router-link>
            </li>
            <li class="details-item">
                <router-link :to="'/policies/ExclusionPolicy'" @click="$emit('close-mobile-sidebar')"
                    class="details-link ac-nav-link">
                    <i class="fa-solid fa-shield-halved"></i>
                    <span class="menu-title">Exclusion Policy</span>
                </router-link>
            </li>
            <li class="details-item">
                <router-link :to="'/policies/ResponsibleGaming'" @click="$emit('close-mobile-sidebar')"
                    class="details-link ac-nav-link">
                    <i class="fa-solid fa-handshake"></i>
                    <span class="menu-title">Responsible Gambling</span>
                </router-link>
            </li>
            <li class="details-item">
                <router-link :to="'/policies/PrivacyPolicy'" @click="$emit('close-mobile-sidebar')"
                    class="details-link ac-nav-link">
                    <i class="fa-solid fa-file-shield"></i>
                    <span class="menu-title">Privacy policy</span>
                </router-link>
            </li>
            <li class="details-item">
                <a class="details-link ac-nav-link" @click="logoutBtnClick();$emit('close-mobile-sidebar')">
                    <i class="fa-solid fa-right-from-bracket" ></i>
                    <span class="menu-title">Sign Out</span>
                </a>
            </li>
        </ul>
    </div>
</template>
<script>

import { AUTH_TOKEN } from '@/shared/constants/cookies-const';
import api from '../../services/api';
import * as apiName from '../../services/urls';
import { getWalletGetwayServiceCall } from '@/shared/services/wallet-service';
import captchaApi from '@/modules/authorization/services/captcha-api';

export default {
    name: 'MobileSideBar',
    data() {
        return {
            isLogin: null,
            userData: null,
        }
    },
    mounted(){
        this.isLogin = this.$store?.getters?.isAuthenticated;
        this.userData = this.$store?.getters?.stateUser;
        console.log("User data : ",this.userData);
    },
    emits: ['close-mobile-sidebar'],
    methods: {
        callPage(pageName) {
            this.$router.push({ name: pageName });
        },
        logoutBtnClick() {
            this.logoutServiceCall();
            //this.clearDataAndRedirect();
        },
        getWalletGateway() {
            getWalletGetwayServiceCall();
        },
        logoutServiceCall() {
            
            let headers = {
                'Authorization': `Bearer ${this.$cookies.get(AUTH_TOKEN)}`
            }
            api.get(apiName.LOGOUT_API, { headers }).then(response => {
                console.log("Result Logout API : ", response);
                this.loading = false;
                if (response) {
                    if (response.status == 200) {
                        console.log("response", response);
                        if (response?.data?.status == 0) {
                            this.$emit('error-modal', response.data.debug[0])
                        }
                        else {
                            this.$emit('success-modal', response?.data?.message);
                            // remove all cookie
                            this.clearDataAndRedirect();
                        }
                    }
                }
            }).catch(error => {
                this.loading = false;
                if (error) {
                    console.log("Error Login : ", error[0]);
                    this.$emit('error-modal', error[0]);
                }
            });
        },
        clearDataAndRedirect() {
            captchaApi.getCaptchaDataServiceCall();
            this.$cookies.keys().forEach(cookie => this.$cookies.remove(cookie))
            this.$store.dispatch('resetAllState');
            this.$router.push({ name: 'Home' });
            this.$refs.close_btn.click();
        }
    }
}

</script>