<template>
    <nav class="navbar navbar-expand-lg bg-white">
        <div class="container">
            <a class="navbar-brand" ><img class="logo-img" src="@/assets/images/logo.png" alt="logo"></a>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
                aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarNav">
                <ul class="navbar-nav">
                    <li class="nav-item">
                        <router-link to="" class="nav-link">sportsbook</router-link>
                    </li>
                    <!-- <li class="nav-item">
                        <router-link to="/" class="nav-link">In PLay</router-link>
                    </li> -->
                    <li class="nav-item" v-for="(sport,n_index) in non_custom_sports" :key="n_index">
                        <a @click="callSportPageFunc(sport)"  class="nav-link">{{sport.name}}</a>
                    </li>
                    <li class="nav-item" v-for="(c_sport,c_index) in custom_sports" :key="c_index">
                        <a @click="callSportPageFunc(c_sport)"  class="nav-link">{{c_sport.name}}</a>
                    </li>
                    <li class="nav-item">
                        <router-link  to="/indian-card-games" class="nav-link"  data-bs-toggle="model">Indian Card Games</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link to="/live-casino"  class="nav-link" >live casino</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link to="/slot-games" class="nav-link" >slots games</router-link>
                    </li>

                </ul>
            </div>
        </div>
    </nav>
</template>

<script>
import api from '../../services/click_api';
import * as apiName from '../../services/urls';
export default{
    name:'NavBar',
    data(){
        return{
            non_custom_sports:[],
            custom_sports:[],
        }
    },
    created(){
        this.get_sportsList()
    },
    methods:{
        callSportPageFunc(sport){
            // this.$router.push({ name: 'sports', params: { type: type}})
            this.$router.push('/sports/' + sport.name + '/' + sport.id)
        },
        get_sportsList(){
            this.loading = true;
            api.get(apiName.GET_SPORTS).then(response => {
                this.loading = false;
                if (response) {
                    if (response.status == 200) {
                        console.log("sports", response);
                        var all_sports = response.data.data
                        this.non_custom_sports=[]
                        for(var i=0;i<all_sports.length;i++){
                            if(all_sports[i].is_custom==0){
                                this.non_custom_sports.push(all_sports[i])
                            }else{
                                this.custom_sports.push(all_sports[i])
                            }
                        }
                        this.non_custom_sports.sort((a, b) => {
                            return a.rank - b.rank;
                        });

                        var sport_list={}
                        sport_list['custom']=this.custom_sports
                        sport_list['non_custom']=this.non_custom_sports
                        localStorage.setItem('sports_List',JSON.stringify(sport_list))
                    }
                }
            }).catch(error => {
                this.loading = false;
                if (error) {
                    console.log("Error : ", error);
                    // this.showErrorModalFunc(error[0]);;
                }
            });
        },
    }
}
</script>