<template>
  <div>

    <div id="mySidenav" class="sidenav" :class="{ 'nav-left': isSideNav }">
      <div class="close-sidebar-menu">
        <!-- <a href="javascript:void(0)" class="closebtn" onclick="closeNav()">&times;</a> -->
      </div>
      <SideMenu @close-side-nav="closeSideNav()" />
    </div>

    <div id="myAcnav" class="sidenav myAcnav" :class="{ 'ac-right': isMobileSideBar }">
      <div class="sidebar-details">
        <MobileSiderBar @close-mobile-sidebar="isMobileSideBar = !isMobileSideBar" />
      </div>
    </div>

    <div id="body-outer" :class="{ 'nav-body': isSideNav, 'ac-body': isMobileSideBar }">
      <div class="overlay-body" @click="isSideNav = false;isMobileSideBar=false"></div>
      <header v-if="checkForHeaderShow()">
        <Header @open-side-nav="isSideNav = !isSideNav" @open-mobile-sidebar="isMobileSideBar = !isMobileSideBar">
        </Header>
      </header>

      <main class="main-content">


        <router-view @error-modal="showErrorModalFunc" @success-modal="showSuccessModalFunc" />

      </main>




      <footer v-if="$route.name == 'Home'">
        <Footer />
      </footer>

    </div>

    <Login @error-modal="showErrorModalFunc" @success-modal="showSuccessModalFunc" />

    <SignUp @error-modal="showErrorModalFunc" @success-modal="showSuccessModalFunc" />

    <ForgetPassword @error-modal="showErrorModalFunc" @success-modal="showSuccessModalFunc" />

    <BonusWallet />

  </div>


  <div :class="{ show: showSuccessModal }" class="successfully-wrapper" style="z-index: 9999999999999999;"
    ref="successmodal" id="successfullyModal">
    <SuccessModal :message="successMsg" />
  </div>

  <div :class="{ show: showErrorModal }" style="z-index: 9999999999999999;" class="successfully-wrapper"
    id="wrongtoaster">
    <ErrorModal :message="errorMsg" />
  </div>


  <ChatModal />

  <GamesPop1 @error-modal="showErrorModalFunc" @success-modal="showSuccessModalFunc" />
  <GamesPop2 @error-modal="showErrorModalFunc" @success-modal="showSuccessModalFunc" />

  <LanguageSelection @error-modal="showErrorModalFunc" @success-modal="showSuccessModalFunc" />
</template>

<script>

import Header from './shared/components/header/Header.vue';
import SideMenu from './shared/components/header/SideMenu.vue';
import Footer from './shared/components/footer/Footer.vue';
import Login from '@/modules/authorization/components/Login.vue';
import SignUp from './modules/authorization/components/SignUp.vue';
import ForgetPassword from '@/modules/authorization/components/ForgetPassword.vue';
import BetSlip from '@/shared/components/bet-slip/BetSlip.vue';
import MobileSiderBar from './shared/components/header/MobileSiderBar.vue';
import api from '@/shared/services/api';
import * as apiName from '@/shared/services/urls';
import axios from 'axios';
import { AUTH_TOKEN } from './shared/constants/cookies-const';
import moment from 'moment';
import SuccessModal from '@/shared/components/modal/SuccessModal.vue';
import ErrorModal from '@/shared/components/modal/ErrorModal.vue';
import BonusWallet from '@/shared/components/available-credit/BonusWallet.vue';
import { setHeaders } from './shared/services/headers';
import ChatModal from './shared/components/footer/ChatModal.vue';

import GamesPop1 from "./shared/components/modal/GamesPop1.vue";
import GamesPop2 from "./shared/components/modal/GamesPop2.vue";
import { callFavouriteApis } from '@/shared/services/common-services';
import * as varConstants from '@/shared/constants/var-constants';
import LanguageSelection from './shared/components/header/LanguageSelection.vue';
import socketService from "./socket/sport-socket-service";
import captchaApi from "./modules/authorization/services/captcha-api";
import { mapGetters } from 'vuex';


export default {
  name: 'App',
  data() {
    return {
      isSideNav: false,
      isMobileSideBar: false,

      onLine: navigator.onLine,
      showBackOnline: false,
      loading: false,

      showSuccessModal: false,
      successMsg: "",
      showErrorModal: false,
      errorMsg: "",
    }
  },
  computed: {
    ...mapGetters({
      captchaData: 'captchaData', siteSettings: 'siteSettings', eventDetailHideSections: 'eventDetailHideSections'
    }),
    routePath() {
      return this.$route.name
    },
    siteSettings() {
      return this.$store.getters.siteSettings;
    },
    domainName() {
      return this.$store.getters.domainName;
    }
  },
  mounted() {
    //for use on js pages
    window.store = this.$store;
    window.cookies = this.$cookies;
    window.router = this.$router;
    window.authorization_token = `Bearer ${this.$cookies.get(AUTH_TOKEN)}`

    //for add on all APIs
    axios.defaults.headers.common['Authorization'] = `Bearer ${this.$cookies.get(AUTH_TOKEN)}`;

    setHeaders();
    if (this.checkIsLogin()) {
      callFavouriteApis();
    }

    this.getSiteSettingsServiceCall();
    if (!this.checkIsLogin()) {
      captchaApi.getCaptchaDataServiceCall();
    }

    //checking internet connection
    window.addEventListener('online', this.updateOnlineStatus);
    window.addEventListener('offline', this.updateOnlineStatus);
    this.socketAllEvents()
  },
  components: {
    Header,
    SideMenu,
    Footer,
    Login,
    SignUp,
    ForgetPassword,
    BetSlip,
    MobileSiderBar,
    SuccessModal,
    ErrorModal,
    BonusWallet,
    ChatModal,
    GamesPop1,
    GamesPop2,
    LanguageSelection
  },
  methods: {
    getSiteSettingsServiceCall() {
      this.loading = true;
      api.get(apiName.SITE_SETTINGS + "?domain=" + window.location.hostname).then(response => {
        console.log("Result Site setting: ", response);
        this.loading = false;
        if (response) {
          if (response.status == 200) {
            console.log("Site setting", response);
            if (response?.data?.status == 0) {
              this.showErrorModalFunc(response.data.debug[0]);
            }
            else {
              //this.showSuccessModalFunc(response.data.message);
              console.log("Site Settings : ", response?.data?.data);
              let resData = response?.data?.data
              this.$store.dispatch('setSettings', resData);
              this.setManifest(resData);

              // Create FAVICON
              const favElement = document.createElement('link');
              favElement.rel = 'icon';
              favElement.href = resData.aws_url + resData.storage_path.domain_image + resData.fav_logo;
              document.head.insertAdjacentElement('beforeend', favElement);

              const title = document.getElementById("title");
						  title.text = resData.domain_name;

              if (this.checkIsLogin())
                this.getWalletBalance();
            }
          }
        }
      }).catch(error => {
        this.loading = false;
        if (error) {
          console.log("Error Login : ", error[0]);
          this.showErrorModalFunc(error[0]);
        }
      });
    },
    setManifest(resData) {
			let manifestName = resData.domain_name.charAt(0).toUpperCase() + resData.domain_name.slice(1).toLowerCase();
			var dynamicManifest = {
				name: manifestName,
				short_name: manifestName,
				start_url: window.location.origin,
				display: 'standalone',
				icons: [{
					src: resData.app_logo ? resData.aws_url + resData.storage_path.domain_image + resData.app_logo : resData.aws_url + resData.storage_path.domain_image + resData.fav_logo,
					sizes: "256x256 512x512 192x192 144x144",
					type: "image/png",
					purpose: 'any'
				}]
			}
			
			// Manifest Blob
			const stringManifest = JSON.stringify(dynamicManifest);
			const blob = new Blob([stringManifest], { type: 'application/json' });
			const manifestURL = URL.createObjectURL(blob);
			
			// Manifest Creation
			const favElement = document.createElement('link');
			favElement.rel = 'manifest';
			favElement.href = manifestURL;
			document.head.insertAdjacentElement('beforeend', favElement);
			
			// IOS APK Logo
			let logo = resData.aws_url + resData.storage_path.domain_image + resData.logo;
			document.querySelector('#appleIcon').setAttribute('href', logo);
			document.querySelector('#appleLaunchIcon').setAttribute('href', logo);

			// IOS App title
			const metaTag = document.createElement('meta');
			metaTag.setAttribute('name', 'apple-mobile-web-app-title');
			metaTag.setAttribute('content', manifestName);
			document.head.insertAdjacentElement('beforeend', metaTag);

		},
    checkIsLogin() {
      return this.$store.getters.isAuthenticated;
    },
    getWalletBalance() {
      let authToken = this.$cookies.get(AUTH_TOKEN);
      if (!authToken) return;
      let headers = {
        'Authorization': `Bearer ${authToken}`
      }
      api.post(apiName.WALLET_BALANCE, {}, { headers }).then(response => {
        if (response && response.status == 200 && response.data) {
          let user = this.$store.getters.stateUser;
          user.balance = response.data.bl;
          user.exposure = response.data.ex;
          user.bets_count = response.data.bc;
          this.$store.dispatch('setUser', user);
          if ((user.exp - moment().utc().unix()) < 10) {
            this.refreshAuthToken(headers);
          } else {
            setTimeout(function () {
              this.getWalletBalance();
            }.bind(this), 2000);
          }
        } else {
          this.$cookies.keys().forEach(cookie => this.$cookies.remove(cookie))
          this.$store.dispatch('resetAllState');
        }
      }).catch(error => {
        if (error) {
          this.showErrorModalFunc(error[0]);
        }
      });
    },
    refreshAuthToken(headers) {
      this.refreshInit = true;
      api.post(apiName.AUTH_REFRESH, {}, { headers }).then(response => {
        let user = this.$store.getters.stateUser;
        user.token = response.data.data.token;
        user.exp = response.data.data.exp;
        this.$cookies.set(AUTH_TOKEN, response.data.data.token);
        this.$store.dispatch('setToken', `Bearer ${response.data.data.token}`);
        window.authorization_token = `Bearer ${this.$cookies.get(AUTH_TOKEN)}`
        //for add on all APIs
        axios.defaults.headers.common['Authorization'] = `Bearer ${this.$cookies.get(AUTH_TOKEN)}`;
        this.$store.dispatch('setUser', user);
        this.refreshInit = false;
        setTimeout(function () {
          this.getWalletBalance();
        }.bind(this), 2000);
      }).catch(error => {
        if (error) {
          this.showErrorModalFunc(error[0]);
        }
        this.refreshInit = false;
      });
    },

    checkForShow(type) {
      let routeName = this.$route.name;
      //for bet slip
      if (type == 2) {
        if (routeName == 'racing-category') {
          return true;
        }
        else {
          return false;
        }
      }
    },
    closeSideNav() {
      if (this.isSideNav)
        this.isSideNav = false;
    },
    updateOnlineStatus(e) {
      const {
        type
      } = e;
      this.onLine = type === 'online';
    },
    checkForHeaderShow() {
			if (this.routePath == 'search') {
				return false;
			} else if (this.eventDetailHideSections && this.eventDetailHideSections.includes('show_full_screen') && this.routePath == 'sports-event-detail') {
				return false;
			} else {
				return true;
			}
		},

    showErrorModalFunc(message) {
      if (message) {
        this.errorMsg = message;
        this.showErrorModal = true;
        setTimeout(() => {
          this.showErrorModal = false;
        }, 2000);
        window.scrollTo(0, 0);
      }
    },
    showSuccessModalFunc(message) {
      if (message) {
        this.successMsg = message;
        this.showSuccessModal = true;
        setTimeout(() => {
          this.showSuccessModal = false;
        }, 2000);
        window.scrollTo(0, 0);
      }
    },
    socketAllEvents() {
      if (this.$store.getters.stateUser) {
        socketService.emit("sub", 'depositRequest-' + this.$store.getters.stateUser.userid);
        socketService.emit("sub", 'logout-' + this.$store.getters.stateUser.userid);
        socketService.emit("sub", 'banned-' + this.$store.getters.stateUser.userid);

        socketService.on("DepositRequest", (data) => {
          if (data.type == "myId") {
            if (data.notify_type == 1) {
              this.$store.dispatch('setNotification');
              this.showSuccessModalFunc('New Notification...')
            }
          }
        });
        socketService.on("logout", (data) => {
          if (data.type == "logout") {
            // logout user from users
            // if (data.notify_type == 1) {
            // 	this.$store.dispatch('setNotification');
            // 	this.showSuccessModalFunc('New Notification...')
            // }
          }
        });
        socketService.on("banned", (data) => {
          if (data.type == "match_ban") {
            // match ban condition
          } else if (data.type == 'league_ban') {
            // league ban condition
          }
        });

      }
    }
  },
  watch: {
    onLine(v) {
      if (v) {
        this.showBackOnline = true;
        setTimeout(() => {
          this.showBackOnline = false;
        }, 1000);
      }
    },
  }
}
</script>
